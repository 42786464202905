import React, { useState } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import globeIcon from "../assets/images/icons/globe-icon.svg";
import { ErrorMessage, Field } from "formik";

export default function ContactDetailSignupStep({ values, setFieldValue }) {
  return (
    <div className="job-post-inner-wrapper">
      <div className="modal-text-wrapper">
        <h2>Contact Details</h2>
        <p className="medium medium-bold">
          Tell us how to reach you for seamless communication.
        </p>
      </div>
      <form action="" className="form">
        <div className="row">
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="email">Phone Number</label>
                <PhoneInput
                  country={"us"}
                  value={values?.phone}
                  onChange={(val) => setFieldValue("phone", val)}
                />
              </div>
              <ErrorMessage
                component="span"
                className="error-msg"
                name="phone"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="field-wrapper">
              <div className="form-group with-left-sign">
                <div className="sign-wrapper">
                  <img src={globeIcon} alt="globeIcon" />
                </div>
                <label htmlFor="email">Website Address</label>
                <Field
                  type="text"
                  className="form-control"
                  placeholder="Enter brand website URL"
                  name="website"
                />
              </div>
              <ErrorMessage
                component="span"
                className="error-msg"
                name="website"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
