import React, { useEffect, useState } from "react";
import AddPaymentModal from "../modals/AddPaymentModal";
import paymenttabCreditcard from "../../assets/images/icons/payementtabCreditcard.svg";
import stripeTextIcon from "../../assets/images/icons/stripe-text-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentMethodsAsyncThunk } from "../../redux/pagesSlices/paymentSlice";
import { dateFormat } from "../../utils/format";
import moment from "moment";
import LoaderComponent from "../PaginateLoaderComponent";

export default function PaymentTab() {
  const { paymentMethods, loadings } = useSelector((s) => s.payment);
  const [addPaymentMethodModa, setAddPaymentMethodModa] = useState(false);
  const dispatch = useDispatch();

  const handleOpenAddPaymentMethodModal = () => {
    setAddPaymentMethodModa(true);
  };
  const handleCloseAddPaymentMethodModal = () => {
    setAddPaymentMethodModa(false);
  };

  useEffect(() => {
    dispatch(getPaymentMethodsAsyncThunk({}));
  }, []);
  const formattedExpiryData = (expiryMonth, expiryYear) => {
    const expiryDate = moment(`${expiryYear}-${expiryMonth}-01`, "YYYY-MM-DD");
    return expiryDate.format("MMM, YYYY");
  };

  return (
    <>
      <div className="payment-tab-wrapper">
        <div className="portal-settings-tab-header payment-header-section">
          <strong className="dark large">Payment methods</strong>
          <button onClick={handleOpenAddPaymentMethodModal}>Add account</button>
        </div>
        <div className="portal-header-bottom-bar"></div>
        {paymentMethods?.length > 0 ? (
          <div className="my-jobs-listings ">
            <div className="table-wrapper over_flow">
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        textAlign: "start",
                      }}
                    >
                      <strong className="white small">Number</strong>
                    </th>
                    <th style={{ textAlign: "start" }}>
                      <strong className="white small">Customer</strong>
                    </th>
                    <th style={{ textAlign: "start" }}>
                      <strong className="white small">Brand</strong>
                    </th>

                    <th style={{ textAlign: "start" }}>
                      <strong className="white small">Expiry Date</strong>
                    </th>
                    <th style={{ textAlign: "start" }}>
                      <strong className="white small">Created</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentMethods?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <p className="dark">
                          ............ {item?.card?.last4}{" "}
                          {item?.isActive === true && (
                            <span className="shadow-sm ms-2 small px-2 py-1 rounded-pill">
                              Default
                            </span>
                          )}
                        </p>
                      </td>
                      <td>
                        <p className="dark">{item?.billing_details?.name}</p>
                      </td>
                      <td>
                        <p className="dark">{item?.card?.display_brand}</p>
                      </td>
                      <td>
                        <p className="dark">
                          {formattedExpiryData(
                            item?.card?.exp_month,
                            item?.card?.exp_year
                          )}
                        </p>
                      </td>
                      <td>
                        <p className="dark">
                          {moment.unix(item?.created).utc().format("DD/MM/YY")}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>  
            </div>
          </div>
        ) : (
          <>
            {loadings.getPaymentMethodsAsyncThunk ? (
              <LoaderComponent />
            ) : (
             <>
              <div className="payment-bottom-content">
                <div className="img-text-section">
                  <img src={paymenttabCreditcard} alt="credit card" />
                  <strong className="dark semi_bold medium">
                    No payment method added yet
                  </strong>
                  <span>
                    Link your credit card or bank account for easy payment.
                  </span>
                </div>
                <button onClick={handleOpenAddPaymentMethodModal}>
                  {" "}
                  Add account
                </button>
              </div>
              <div className="payment-detail-main-wrapper">
                <div className="number-detail">
                  <div className="bank-icon">
                  <img src={stripeTextIcon} alt="stripeTextIcon" />
                  </div>
                  <div className="text-wrapper">
                   <div className="text">
                   <strong className="semi_bold dark">John Connors</strong>
                   <p className="medium">1234****</p>
                   </div>
                   <div className="btn-wrapper">  
                    <a href="#" className="btn-style transparent">Disconnect bank</a>
                   </div>
                  </div>
                </div>
              </div>
             </>
            )}
          </>
        )}
      </div>
      <AddPaymentModal
        handleCloseAddPaymentMethodModal={handleCloseAddPaymentMethodModal}
        addPaymentMethodModa={addPaymentMethodModa}
      />
    </>
  );
}
