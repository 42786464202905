import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { SlHandbag } from "react-icons/sl";
import { LuGlobe } from "react-icons/lu";
import { PiLinkSimpleBold } from "react-icons/pi";

import leftLongIcon from "../assets/images/icons/left-long-arrow.svg";
import mapIcon from "../assets/images/icons/map-icon.svg";
import jobImg from "../assets/images/icons/job-image.svg";
import proposalImg from "../assets/images/proposal-img.svg";
import videoMediaImg from "../assets/images/video-media-img.svg";
import videoIcon from "../assets/images/icons/video-file-wrapper.svg";
import instagramIcon from "../assets/images/icons/instagram-colored-icon.svg";
import facebookIcon from "../assets/images/icons/facebook-colored-icon.svg.svg";
import tiktokIcon from "../assets/images/icons/tiktok-colored-icon.svg.svg";
import xIcon from "../assets/images/icons/x-colored-icon.svg.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import LiveIcon from "../assets/images/icons/live-icon.svg";
import NoLinksComponent from "../components/NoLinksComponent";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { returnArray } from "../utils/utils";
import LoaderComponent from "../components/LoaderComponent";
import { MdVerifiedUser } from "react-icons/md";
import { dateFormat } from "../utils/format";
import moment from "moment";

export default function JobDetailFullSection({ jobs,jobId }) {
  const d = useDispatch();
  const { id } = useParams();
  const [viewMorePainPoints, setViewMorePainPoints] = useState(false);
  const proposalDetails = useSelector((s) => s.application.application);
  const videoRefs = useRef([]);
  const [playingIndex, setPlayingIndex] = useState(null); // Track which video is playing
  const loading = useSelector(
    (s) => s.application?.loadings?.getApplicationAsyncThunk
  );

  // useEffect(() => {
  //   if (id) d(getApplicationAsyncThunk(id));
  // }, [d, id]);

  const videoPrices = returnArray(proposalDetails?.offeredVideoPrice);
  const livePrices = returnArray(proposalDetails?.offeredLivePrice);
  const brand = proposalDetails?.brandId;
  const job = proposalDetails?.jobId ?? {};
  console.log("Application Details", proposalDetails);
  const brandWebsite = proposalDetails?.brandId?.website;

  const videoArray = job?.videoUrl?.split(",").map((url) => url.trim());

  const brandName = proposalDetails?.brandId?.name;
  const numberOfVideos = returnArray(videoPrices).reduce(
    (acc, item) => acc + item.count,
    0
  );
  const numberOfLives = returnArray(livePrices).reduce(
    (acc, item) => acc + item.count,
    0
  );
  const handlePlay = (index) => {
    const videoElement = videoRefs.current[index];
    if (videoElement.paused) {
      videoElement.play();
      setPlayingIndex(index);
    } else {
      videoElement.pause();
      setPlayingIndex(null);
    }
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="project-detail-section">
      <div className="container ">
        <div className="row">
          <div className="col-md-8">
            <div className="about-project-detal-wrapper">
              <div className="about-job-content-wrapper">
                <div className="text-wrapper">
                  <strong className="medium">Job description</strong>
                  <p>{jobs?.job?.brandId?.description}</p>
                </div>
                <div className="text-wrapper">
                  <strong className="medium"> Pain points</strong>
                  <p>
                    {/* {job?.jobAttributes?.painPoints?.length > 200
                      ? viewMorePainPoints === true
                        ? job?.jobAttributes?.painPoints
                        : job?.jobAttributes?.painPoints?.slice(0, 200) + "..."
                      : job?.jobAttributes?.painPoints} */}
                    {jobs?.job?.jobAttributes?.painPoints}
                  </p>

                  {job?.jobAttributes?.painPoints?.length > 200 &&
                    !viewMorePainPoints && (
                      <button
                        onClick={() => setViewMorePainPoints(true)}
                        className="btn btn-link text-decoration-none blue"
                      >
                        View more <IoIosArrowDown />
                      </button>
                    )}
                </div>
                <div className="text-wrapper">
                  <strong className="medium">Content Strategy Guidance</strong>
                  <p>
                    {/* {job?.jobAttributes?.painPoints?.length > 200
                      ? viewMorePainPoints === true
                        ? job?.jobAttributes?.painPoints
                        : job?.jobAttributes?.painPoints?.slice(0, 200) + "..."
                      : job?.jobAttributes?.painPoints} */}
                    {jobs?.job?.jobAttributes?.contentStrategy}
                  </p>

                  {job?.jobAttributes?.painPoints?.length > 200 &&
                    !viewMorePainPoints && (
                      <button
                        onClick={() => setViewMorePainPoints(true)}
                        className="btn btn-link text-decoration-none blue"
                      >
                        View more <IoIosArrowDown />
                      </button>
                    )}
                </div>
                <div className="text-wrapper">
                  <strong className="medium">Job benefits</strong>
                  <p>
                    {/* {jobs?.job?.jobAttributes?.benefits?.length > 200
                      ? viewMorePainPoints === true
                        ? jobs?.jobAttributes?.benefits
                        : jobs?.jobAttributes?.benefits?.slice(0, 200) + "..."
                      : jobs?.jobAttributes?.benefits} */}
                    {jobs?.job?.jobAttributes?.benefits}
                  </p>

                  {job?.jobAttributes?.painPoints?.length > 200 &&
                    !viewMorePainPoints && (
                      <button
                        onClick={() => setViewMorePainPoints(true)}
                        className="btn btn-link text-decoration-none blue"
                      >
                        View more <IoIosArrowDown />
                      </button>
                    )}
                </div>
              </div>
              <div className="media-and-resources-wrapper">
                <div className="media-wrapper">
                  <strong className="bold dark">Photos</strong>
                  <ul className="images-media-wrapper">
                    {jobs?.job?.image?.map((item, index) => (
                      <li key={index}>
                        <img
                          src={parseImagePathToURL(item)}
                          alt="proposalImg"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="media-wrapper">
                  <strong className="bold dark">Videos</strong>
                  <ul className="videos-media-wrapper">
                    {jobs?.job?.videoExamples?.length > 0 &&
                      jobs?.job?.videoExamples?.map((video, index) => (
                        <li key={index}>
                          <video
                            ref={(el) => (videoRefs.current[index] = el)} // Set ref for each video
                            controls
                            width="600"
                            poster={videoMediaImg}
                            onPlay={() => setPlayingIndex(index)} // Set index when video starts playing
                            onPause={() => setPlayingIndex(null)} // Reset index when video is paused
                          >
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          {playingIndex !== index && (
                            <div
                              className="icon-wrapper"
                              onClick={() => handlePlay(index)}
                            >
                              <FaPlay />
                            </div>
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="summary-main-wrapper">
              <strong className="large dark">Job details</strong>
              <div className="top-bar-wrapper">
                <div className="text">
                  <strong>Job posted</strong>
                  <p className="dark_text semi_bold">
                    {dateFormat(jobs?.job?.createdAt)}{" "}
                    <span className="small gray">
                      {" "}
                      ({moment(jobs?.job?.createdAt).fromNow()})
                    </span>
                  </p>
                </div>
                <div className="text">
                  <strong>Content type</strong>
                  <ul className="proposal-type-list">
                    {jobs?.job?.types?.map((type, index) => (
                      <li
                        key={index}
                        className={`${type == "video" ? "light-gray-bg" : "light-pink-bg"
                          }`}
                      >
                        <img
                          src={type === "video" ? videoIcon : LiveIcon}
                          alt="Icon"
                        />
                        {type}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="text">
                  <strong>Categories</strong>
                  <ul className="categories-list">
                    {jobs?.job?.categories.map((category, index) => (
                      <li key={index}>{category}</li>
                    ))}
                  </ul>
                </div>
                <div className="text">
                  <strong>Preferred ages</strong>
                  <p className="light-bg">
                    {returnArray(jobs?.job?.ageRange).join(", ")}
                  </p>
                </div>
                <div className="text">
                  <strong>Preferred gender</strong>
                  <p className="light-bg">{jobs?.job?.gender}</p>
                </div>
                <div className="text">
                  <strong>Location</strong>
                  <p className="dark_text medium semi_bold">{jobs?.job?.location}</p>
                </div>
                <div className="text">
                  <strong>Language</strong>
                  <p className="dark_text medium semi_bold">English</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
