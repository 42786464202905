import React, { useState } from "react";

import BannerImg from '../assets/images/dashboard-home-banner-img.svg'
import noJobImg from '../assets/images/no-job-img.svg'
import arrowInBoxIcon from '../assets/images/icons/arrow-in-box.svg'
import noCreditCard from '../assets/images/no-credit-card.svg'
import BrandSignupStepperModal from "../components/BrandSignupStepperModal";
import { showUserName } from "../utils/format";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function DashboardHomeNoData() {

    const [modalState, setModalState] = useState(false);
    const user = useSelector(s => s.auth.user);

    const modalOpenHandler = () => {
        setModalState(true)
    }
    const modalCloseHandler = () => {
        setModalState(false)
    }

    return (
        <div className="inner-page-wrapper active m-0">
            <div className="dashboard-home-page">
                <div className="banner-section">
                    <div className="container ex-large">
                        <div className="row">
                            <div className="col-md-7 align-self-center">
                                <div className="banner-content">
                                    <h2 className="white">Welcome, {showUserName(user)}!</h2>
                                    <p className="large medium-bold">Ready to kick off your journey? Discover job listings, set your rates, and showcase your unique skills to attract brands.</p>
                                </div>
                            </div>
                            <div className="col-md-5 align-self-center">
                                <div className="banner-img">
                                    <img src={BannerImg} alt="BannerImg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-boarding-jobs-section">
                    <div className="row">
                        <div className="col-md-7 mb-4">
                            <div className="job-info-wrapper">
                                <div className="title-wrapper">
                                    <strong className="large dark">Ongoing Job Status</strong>
                                    <div className="info-icon-wrapper">
                                        <a href="#">
                                            <img src={arrowInBoxIcon} alt="arrowInBoxIcon" />
                                        </a>
                                    </div>
                                </div>
                                <div className="inner-content-wrapper">
                                    <div className="no-home-card-data-wrapper text-center">
                                        <div className="img-wrapper">
                                            <img src={noJobImg} alt="noJobImg" />
                                        </div>
                                        <div className="text-wrapper">
                                            <strong className="dark medium">No jobs on your radar yet!</strong>
                                            <p>Browse available opportunities and start applying to bring in <br /> exciting projects.</p>
                                            <Link to="/dashboard/find-jobs" className="btn-style blue-border">Find jobs</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 mb-4">
                            <div className="job-info-wrapper">
                                <div className="title-wrapper">
                                    <strong className="large dark">Ongoing Job Status</strong>
                                    <div className="info-icon-wrapper">
                                        <a href="#">
                                            <img src={arrowInBoxIcon} alt="arrowInBoxIcon" />
                                        </a>
                                    </div>
                                </div>
                                <div className="inner-content-wrapper">
                                    <div className="no-home-card-data-wrapper text-center">
                                        <div className="img-wrapper">
                                            <img src={noCreditCard} alt="noCreditCard" />
                                        </div>
                                        <div className="text-wrapper">
                                            <strong className="dark medium">Looks like your pricing details are empty!</strong>
                                            <p>No worries—let's get you set up! Add your service pricing to keep track of your offerings and help your clients understand the value you provide.</p>
                                            <a href="#" className="btn-style blue-border" onClick={modalOpenHandler}>Set pricings</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 mb-4">
                            <div className="job-info-wrapper">
                                <div className="title-wrapper">
                                    <strong className="large dark">Trends</strong>
                                    <div className="info-icon-wrapper">
                                        <a href="#">
                                            <img src={arrowInBoxIcon} alt="arrowInBoxIcon" />
                                        </a>
                                    </div>
                                </div>
                                <div className="inner-content-wrapper">
                                    <div className="no-home-card-data-wrapper text-center">
                                        <div className="img-wrapper">
                                            <img src={noJobImg} alt="noJobImg" />
                                        </div>
                                        <div className="text-wrapper">
                                            <strong className="dark medium">No jobs on your radar yet!</strong>
                                            <p>Browse available opportunities and start applying to bring in <br /> exciting projects.</p>
                                            <Link to="/dashboard/find-jobs" className="btn-style blue-border">Find jobs</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="job-info-wrapper">
                                <div className="title-wrapper">
                                    <strong className="large dark">My Messages</strong>
                                    <div className="info-icon-wrapper">
                                        <Link to="/dashboard/messages">
                                            <img src={arrowInBoxIcon} alt="arrowInBoxIcon" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="inner-content-wrapper ">
                                    <div className="no-home-card-data-wrapper text-center">
                                        <div className="img-wrapper">
                                            <img src={noCreditCard} alt="noCreditCard" />
                                        </div>
                                        <div className="text-wrapper">
                                            <strong className="dark medium">No jobs on your radar yet!</strong>
                                            <p>Browse available opportunities and start applying to bring in  exciting projects.</p>
                                            <Link to="/dashboard/find-jobs" className="btn-style blue-border">Find jobs</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BrandSignupStepperModal
                    show={modalState}
                    onHide={() => setModalState(false)}
                    modalCloseHandler={modalCloseHandler}
                />
            </div>
        </div>
    )
}