import React from "react";

import checkFillCircular from '../assets/images/icons/check-fill-icon-circular.svg'
import { Link } from "react-router-dom";
import LogoTopBar from "../components/LogoTopBar";
import LoginAsBrandBottomBar from "../components/LoginAsBrandBottomBar";

export default function SuccessChangedPasswoedTemplate() {
    return (
        <div className="email-sent-template-page">
            <div className="inner-main-page">
                <LogoTopBar />
            </div>
            <div className="inner-wrapper">
                <div className="main-wrapper text-center">
                    <div className="img-wrapper">
                        <img src={checkFillCircular} alt="checkFillCircular" />
                    </div>
                    <div className="content-wrapper">
                        <h2>Success!
                        </h2>
                        <p className="dark_text">Your password has been updated successfully. You can <br /> now proceed to log in.</p>
                    </div>
                    <div className="btn-wrapper">
                        <Link to="/sign-in" className="btn-style full large_font">Return to log in</Link>
                    </div>
                </div>
            </div>
            <LoginAsBrandBottomBar />
        </div>
    )
}