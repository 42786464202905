import React, { useState } from 'react';
import { Button, Row, Col, Modal, Form, Card } from 'react-bootstrap';
import { ApiRequests } from '../service/ApiRequests';

export default function AsadPage() {
  const [showModal, setShowModal] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [apiResponse, setApiResponse] = useState(null); // New state to store API response

  const requestButtons = [
    {
      label: 'get auth info',
      action: async () => {
        const res = await ApiRequests.getAuthDetails();
        setApiResponse(res.data); // Store the result in state
      }
    },
    {
      label: 'put callback info',
      action: async () => {
        // Collecting inputs through modal
        setCurrentAction('authCallback');
        setShowModal(true);
      }
    },
    {
      label: 'create Target Collaboration',
      action: async () => {
        const res = await ApiRequests.tiktokCreateTargetCollaborations();
        setApiResponse(res.data); // Store the result in state
      }
    },
    {
      label: 'get Target Collaborations',
      action: async () => {
        const res = await ApiRequests.tiktokGetTargetCollaborations();
        setApiResponse(res.data); // Store the result in state
      }
    },
    {
      label: 'get single TargetCollaboration',
      action: async () => {
        const id = prompt('Enter the ID of the Target Collaboration');
        const res = await ApiRequests.tiktokGetTargetCollaboration(id);
        setApiResponse(res.data); // Store the result in state
      }
    },
    {
      label: 'get Get Sample Application',
      action: async () => {
        const res = await ApiRequests.tiktokGetSampleApplication();
        setApiResponse(res.data); // Store the result in state
      }
    },
    {
      label: 'get SampleApplication fulfillment',
      action: async () => {
        const id = prompt('Enter the ID of the Target Collaboration');

        const res = await ApiRequests.tiktokGetSampleApplicationFulfillment(id);
        setApiResponse(res.data); // Store the result in state
      }
    },
    {
      label: 'get ShopPerformance',
      action: async () => {
        const res = await ApiRequests.tiktokGetAnalyticsShopPerformance();
        setApiResponse(res.data); // Store the result in state
      }
    },
    {
      label: 'get Shop products Performance',
      action: async () => {
        const res = await ApiRequests.tiktokGetAnalyticsShopProductsPerformance();
        setApiResponse(res.data); // Store the result in state
      }
    },
    {
      label: 'get Shop product Performance',
      action: async () => {
        const id = prompt('Enter the ID of the Product');
        const res = await ApiRequests.tiktokGetAnalyticsShopProductPerformance(id);
        setApiResponse(res.data); // Store the result in state
      }
    },
    {
      label: 'get Shop Videos Performance',
      action: async () => {
        const res = await ApiRequests.tiktokGetAnalyticsShopVideosPerformance();
        setApiResponse(res.data); // Store the result in state
      }
    },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    setShowModal(false);

    if (currentAction === 'authCallback') {
      const { app_key, code, locale, shop_region, state } = inputValues;
      const res = await ApiRequests.getAuthCallback({
        app_key,
        code,
        locale,
        shop_region,
        state
      });
      setApiResponse(res.data); // Store the result in state
    }
  };

  return (
    <div>
      <h1 style={{ color: 'black' }}>API Request Buttons</h1>
      <Row>
        {requestButtons.map((button, index) => (
          <Col key={index} md={4} sm={6} xs={12} className="mb-3">
            <Button onClick={button.action} block>
              {button.label}
            </Button>
          </Col>
        ))}
      </Row>

      {/* Modal for handling input requests */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Input for {currentAction}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAction === 'authCallback' && (
            <>
              <Form.Group controlId="appKey">
                <Form.Label>App Key</Form.Label>
                <Form.Control
                  type="text"
                  name="app_key"
                  value={inputValues.app_key || ''}
                  onChange={handleInputChange}
                  placeholder="Enter App Key"
                />
              </Form.Group>
              <Form.Group controlId="code">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  type="text"
                  name="code"
                  value={inputValues.code || ''}
                  onChange={handleInputChange}
                  placeholder="Enter Code"
                />
              </Form.Group>
              <Form.Group controlId="locale">
                <Form.Label>Locale</Form.Label>
                <Form.Control
                  type="text"
                  name="locale"
                  value={inputValues.locale || ''}
                  onChange={handleInputChange}
                  placeholder="Enter Locale"
                />
              </Form.Group>
              <Form.Group controlId="shopRegion">
                <Form.Label>Shop Region</Form.Label>
                <Form.Control
                  type="text"
                  name="shop_region"
                  value={inputValues.shop_region || ''}
                  onChange={handleInputChange}
                  placeholder="Enter Shop Region"
                />
              </Form.Group>
              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={inputValues.state || ''}
                  onChange={handleInputChange}
                  placeholder="Enter State"
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Display the API response in JSON format */}
      {apiResponse && (
        <Card className="mt-4">
          <Card.Body>
            <Card.Title>API Response</Card.Title>
            <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
