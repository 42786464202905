import React from "react";
import tickCircle from "../assets/images/icons/tick-circle-outlne.svg";
import { useDispatch } from "react-redux";
import { checkoutAsyncThunk } from "../redux/pagesSlices/paymentSlice";
import { toast } from "react-toastify";
import { getStripe } from "../utils/stripe/client";
import { FaArrowRight } from "react-icons/fa6";
import { Spinner } from "react-bootstrap";

export default function MemberShipCard({
  planName,
  price,
  frequency,
  features,
  recommended,
  loading,
  handleSetLoading,
}) {
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    handleSetLoading(planName, true); // Set loading for this specific plan
    try {
      const stripe = await getStripe();
      const data = { plan: planName };
      console.log("data", data);
      const stripeCheckout = await dispatch(
        checkoutAsyncThunk({
          data,
        })
      ).unwrap();
      console.log("stripeCheckout", stripeCheckout);
      stripe.redirectToCheckout({ sessionId: stripeCheckout?.id });
      toast.success(
        "Please wait a moment while we securely redirect you to the payment page"
      );
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "Something went wrong");
    } finally {
      handleSetLoading(planName, false); // Reset loading state for this specific plan
    }
  };

  return (
    <div className="member-ship-card-wrapper">
      <div className="membership-title-wrapper">
        <div className="title">
          <span className="blue">{planName.toUpperCase()}</span>
          {recommended && <span className="bage gradient-bg">Recommended</span>}
        </div>
        <h2 className="large">
          ${price}
          <sub>{frequency}</sub>
        </h2>
        <button
          onClick={handleSubmit}
          type="button"
          className="btn-style blue-border full"
          disabled={loading}
        >
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <>
              Get started <FaArrowRight />
            </>
          )}
        </button>
      </div>
      <div className="member-ship-features-wrapper mt-4">
        <span>Features:</span>
        <ul className="features">
          {features.map((feature, index) => (
            <li key={index}>
              <img src={tickCircle} alt="tickCircle" />
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
