import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Creditcardicon from "../../assets/images/icons/Creditcardicon.svg";
import Bankicon from "../../assets/images/icons/Bankicon.svg";
import EditCompanyModal from "./EditCompanyModal";

const AddPaymentModal = ({
  addPaymentMethodModa,
  handleCloseAddPaymentMethodModal,
}) => {
  const [selectedCard, setSelectedCard] = useState(null); // To track the selected ca
  // Function to handle card selection
  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const [editCompanyModal, setSditCompanyModal] = useState(false);

  const handleCloseEditCompanyModal = () => setSditCompanyModal(false);
  const handleShowEditCompanyModal = () => setSditCompanyModal(true);

  return (
    <>
      <Modal
        show={addPaymentMethodModa}
        onHide={handleCloseAddPaymentMethodModal}
        centered
        className="custom-modal add-payment-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add payment method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div
                className={`box-card ${
                  selectedCard === "credit" ? "selected" : ""
                }`}
                onClick={() => {
                  handleCardClick("credit");
                  handleShowEditCompanyModal();
                }}
              >
                <img src={Creditcardicon} alt="Credit Card Icon" />
                <div className="box-card-text-section">
                  <div className="title">
                    <strong className="dark">Credit/Debit Card</strong>
                    <p>
                      Payments are processed in seconds and Stripe fees can
                      charge from 2% up to 5%.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={`box-card ${
                  selectedCard === "bank" ? "selected" : ""
                }`}
                onClick={() => handleCardClick("bank")}
              >
                <img src={Bankicon} alt="Bank Icon" />
                <div className="box-card-text-section">
                  <div className="title">
                    <strong className="dark">Bank Transfer</strong>
                    <p>
                      Payments can take up to 2 to 5 days and Stripe fees are
                      0.8%, capped at $5 per payment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="add-payment-footer">
          <button
            className="cancel-button"
            onClick={handleCloseAddPaymentMethodModal}
          >
            Cancel
          </button>
          <button className="btn-style">Continue</button>
        </Modal.Footer>
      </Modal>
      <EditCompanyModal
        handleCloseEditCompanyModal={handleCloseEditCompanyModal}
        editCompanyModal={editCompanyModal}
      />
    </>
  );
};

export default AddPaymentModal;
