import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import { BiErrorCircle } from "react-icons/bi";
import PaginationComponent from "../components/PaginationComponent";
import PaginateLoaderComponent from "../components/PaginateLoaderComponent";

function ProviderErrorLoadingPagination({
  reducer,
  action,
  Parent = ({ children }) => <>{children}</>,
  LoaderParent = ({ children }) => <>{children}</>,
  dataKey,
  Component,
  asyncThunk,
  emptyMessage = "No Data Found!",
  emptyComponent,
  componentProps = {},
  pagination = true,
  itemKey = "item",
}) {
  const dispatch = useDispatch();

  // Optimized state selector
  const loading = useSelector((state) => state[reducer]?.loadings?.[action]);
  const response = useSelector((state) => state[reducer]?.[dataKey]);
  const error = useSelector((state) => state[reducer]?.errors?.[action]);
  const serverParams = useSelector(
    (state) => state[reducer]?.paramsForThunk?.[action]
  );
  const errorMessages = useSelector(
    (state) => state[reducer]?.errorMessages?.[action]
  );

  // Memoized event handlers
  const goToPage = useCallback(
    (page) => {
      var reqParams = { ...(serverParams ?? {}), page };
      if (serverParams?.params) {
        reqParams = { ...reqParams, params: { ...serverParams?.params, page } };
      }
      dispatch(asyncThunk(reqParams));
    },
    [dispatch, asyncThunk, serverParams]
  );

  const handlePerPageChange = useCallback(
    (limit) => {
      var reqParams = { ...(serverParams ?? {}), limit };
      if (serverParams?.params) {
        reqParams = {
          ...reqParams,
          params: { ...serverParams?.params, limit },
        };
      }
      dispatch(asyncThunk(reqParams));
    },
    [dispatch, asyncThunk, serverParams]
  );

  const renderError = useCallback(
    () => (
      <div className="data-error">
        <Row>
          <Col lg={6}>
            <div className="img-box">
              <BiErrorCircle size={100} color="#d3d3d3" />
            </div>
          </Col>
          <Col lg={6}>
            <div className="text-box">
              <h1>{errorMessages || "Something went wrong"}</h1>
              <p>Something went wrong, click to reload.</p>
              <button
                onClick={() => dispatch(asyncThunk(serverParams || {}))}
                className="btn btn-primary"
              >
                Reload
              </button>
            </div>
          </Col>
        </Row>
      </div>
    ),
    [dispatch, asyncThunk, serverParams, errorMessages]
  );

  // Render error state
  if (error) return renderError();

  // Render empty state
  if (!response?.results?.length && !loading) {
    const EmptyContent = emptyComponent || (
      <Container className="text-center">{emptyMessage}</Container>
    );

    return LoaderParent && typeof LoaderParent === "function" ? (
      <LoaderParent>{EmptyContent}</LoaderParent>
    ) : (
      EmptyContent
    );
  }

  // Render main content
  return (
    <>
      <Parent>
        {(() => {
          const usedIds = new Set(); 
          return response?.results?.map((item, index) => {
            const isUnique = item?.id && !usedIds.has(item.id);
            if (isUnique) usedIds.add(item.id); 
            const uniqueKey = isUnique ? item.id : index;
            return (
              <Component
                {...componentProps}
                key={uniqueKey}
                {...{ [itemKey]: item }}
              />
            );
          });
        })()}
      </Parent>
      {loading && <PaginateLoaderComponent />}
      {pagination && (
        <PaginationComponent
          onItemsPerPageChange={handlePerPageChange}
          onPageChange={goToPage}
          initialItemsPerPage={response?.limit}
          initialPage={response?.page}
          totalItems={response?.totalResults}
        />
      )}
    </>
  );
}

export default React.memo(ProviderErrorLoadingPagination);
