export const categoriesLabels = {
  Electronics: "⚡ Electronics",
  Fashion: "👗 Fashion",
  "Beauty & Health": "🍎 Beauty & Health",
  "Home & Living": "🏠 Home & Living",
  "Toys & Games": "🎮 Toys & Games",
  Automotive: "🚗 Automotive",
  "Books & Media": "📷 Books & Media",
  "Groceries & Food": "🛒 Groceries & Food",
  "Pet Supplies": "🐶 Pet Supplies",
  "Office Supplies": "💼 Office Supplies",
  "Tools & Hardware": "🛠️ Tools & Hardware",
  "Jewellery & Watches": "💎 Jewellery & Watches",
  "Sports & Outdoors": "🏃🏻 Sports & Outdoors",
  "Baby & Kids": "👶🏻 Baby & Kids",
  "Arts & Collectibles": "🎨 Arts & Collectibles",
};
