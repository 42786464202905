import React, { useState } from 'react';
import { FaRegCheckCircle } from "react-icons/fa";

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { LuEyeOff } from "react-icons/lu";
import googleIcon from '../assets/images/icons/google-icon.svg';
import circularCrossIcon from '../assets/images/icons/circular-cross-icon.svg';
import { FaArrowLeftLong } from "react-icons/fa6";

import LogoTopBar from '../components/LogoTopBar';
import InvalidEmailPassword from '../components/InvalidEmailPassword';
import LoginAsBrandBottomBar from '../components/LoginAsBrandBottomBar';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginAsyncThunk } from '../redux/pagesSlices/authSlice';


const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Must contain at least one lowercase character')
        .matches(/[A-Z]/, 'Must contain at least one uppercase character')
        .matches(/[0-9]/, 'Must contain at least one number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must contain at least one special character'),
});


export default function EnterNewPassword() {
    const d = useDispatch();
    const loading = useSelector(s => s.auth?.loadings?.userLoginAsyncThunk);
    const router = useNavigate();

    const handleSubmit = async (values, { resetForm }) => {
        await d(userLoginAsyncThunk({ email: values.email, password: values.password, router }));
    }
    return (
        <div className='forget-password-page'>
            <div className="inner-main-page">
                <LogoTopBar />
                <div className="inner-page-content">
                    <div className="login-page-content">
                        <div className="title-wrapper text-center">
                            <h2>Forgot Password?</h2>
                            <p className='dark_text'> Enter your new password and you’re good to go.</p>
                        </div>

                        {/* {showError && <InvalidEmailPassword />} */}

                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={validationSchema}
                            // onSubmit={(values, { setSubmitting }) => {
                            //     console.log('Form submitted:', values);
                            //     setSubmitting(false); // Stop submit button loading after submission
                            // }}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, values, errors }) => (
                                <Form className='form transparent'>
                                    <div className="field-wrapper">
                                        <div className="form-group with-right-icon">
                                            <label htmlFor="password">Password</label>
                                            <Field type="password" className="form-control" placeholder='Password' id='password' name="password" />
                                            <LuEyeOff style={{cursor:'pointer'}} />
                                        </div>
                                        <span className="error-msg"><ErrorMessage name='password' /></span>
                                    </div>
                                    <ul className="pass-feature">
                                        <li className={values.password.match(/[a-z]/) ? 'active' : ''}><FaRegCheckCircle /> One lowercase character</li>
                                        <li className={values.password.length >= 8 ? 'active' : ''}><FaRegCheckCircle /> 8 character minimum</li>
                                        <li></li>
                                        <li className={values.password.match(/[!@#$%^&*(),.?":{}|<>]/) ? 'active' : ''}><FaRegCheckCircle /> One special character</li>
                                        <li className={values.password.match(/[0-9]/) ? 'active' : ''}><FaRegCheckCircle /> One number</li>
                                        <li></li>
                                        <li className={values.password.match(/[A-Z]/) ? 'active' : ''}><FaRegCheckCircle /> One uppercase character</li>
                                    </ul>
                                    <div className="field-wrapper">
                                        <div className="form-group with-right-icon">
                                            <label htmlFor="password">Confirm password</label>
                                            <Field type="password" className="form-control" placeholder='Password' id='password' name="password" />
                                            <LuEyeOff style={{cursor:'pointer'}} />
                                        </div>
                                        <span className="error-msg"><ErrorMessage name='password' /></span>
                                    </div>
                                    <div className="form-footer">
                                        {/* <button type="submit" disabled={isSubmitting} className='btn-style full'> Next  </button> */}
                                        <Link to='/password-changed' className='btn-style full large_font'>Reset Password</Link>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <LoginAsBrandBottomBar />
        </div>
    )
}