import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentMethodAsyncThunk } from "../../redux/pagesSlices/paymentSlice";

const EditCompanyModal = ({
  handleCloseEditCompanyModal,
  editCompanyModal,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const user = useSelector((s) => s.auth.user);
  const { loadings } = useSelector((s) => s.payment);

  const [errors, setErrors] = useState({
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({ cardNumber: "", cardExpiry: "", cardCvc: "" }); // Reset errors

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    // Validation happens when Stripe's API is invoked
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement, // Automatically links all the fields
      billing_details: {
        name: user?.name,
      },
    });

    if (error) {
      if (error?.code === "incomplete_number") {
        setErrors((prev) => ({ ...prev, cardNumber: error.message }));
      } else if (error?.code === "incomplete_expiry") {
        setErrors((prev) => ({ ...prev, cardExpiry: error.message }));
      } else if (error?.code === "incomplete_cvc") {
        setErrors((prev) => ({ ...prev, cardCvc: error.message }));
      } else {
        console.error("General Error:", error.message);
      }
      return;
    }

    dispatch(
      addPaymentMethodAsyncThunk({
        data: { paymentMethodId: paymentMethod.id },
        callback: () => {
          handleCloseEditCompanyModal();
        },
      })
    );
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        color: "#495057",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        "::placeholder": {
          color: "#6c757d",
        },
      },
      invalid: {
        color: "#dc3545",
      },
    },
  };

  return (
    <Modal
      show={editCompanyModal}
      onHide={handleCloseEditCompanyModal}
      centered
      className="custom-modal edit-company-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Company Profile</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit} className="form">
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="field-wrapper">
                <div className="form-group">
                  <label htmlFor="cardNumber">Card Number</label>
                  <CardNumberElement
                    id="cardNumber"
                    options={CARD_ELEMENT_OPTIONS}
                    className="form-control calender-input"
                  />
                  {errors.cardNumber && (
                    <div className="error-msg">{errors.cardNumber}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="field-wrapper">
                <div className="form-group">
                  <label
                    htmlFor="expirationDate"
                    className="readonly-input-label"
                  >
                    Expiration Date
                  </label>
                  <CardExpiryElement
                    id="expiry"
                    options={CARD_ELEMENT_OPTIONS}
                    className="form-control calender-input"
                  />
                  {errors.cardExpiry && (
                    <div className="error-msg">{errors.cardExpiry}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="field-wrapper">
                <div className="form-group">
                  <label
                    htmlFor="securityCode"
                    className="readonly-input-label"
                  >
                    Security Code
                  </label>
                  <CardCvcElement
                    id="cvc"
                    options={CARD_ELEMENT_OPTIONS}
                    className="form-control calender-input"
                  />
                  {errors.cardCvc && (
                    <div className="error-msg">{errors.cardCvc}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="add-payment-footer">
          <button
            type="button"
            className="cancel-button"
            onClick={handleCloseEditCompanyModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn-style"
            disabled={loadings.addPaymentMethodAsyncThunk}
          >
            {loadings.addPaymentMethodAsyncThunk ? <Spinner /> : "Save"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditCompanyModal;
