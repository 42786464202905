import React, { useState } from "react";
import Google from "../../assets/images/icons/Google.svg";
import ChangePasswodModal from "../modals/ChangePasswodModal";
import { useDispatch } from "react-redux";
import { userLogoutAsyncThunk } from "../../redux/pagesSlices/authSlice";

export default function SecurityTab() {
  const [currentPass, setCurrentPass] = useState(null);
  const [currentPassError, setCurrentPassError] = useState(false);
  const [passModel, setPassModel] = useState(false);
  const d = useDispatch();

  const handleOpenPassModel = () => {
    if (!currentPass || currentPass === "") {
      setCurrentPassError(true);
      return;
    }
    setPassModel(true);
  };

  const handleClosePassModel = () => {
    setPassModel(false);
  };
  return (
    <>
      <div className="password-security-tab-wrapper">
        <div className="portal-settings-tab-header">
          <strong className="dark large">Password & Security</strong>
        </div>
        <div className="portal-header-bottom-bar "></div>
        <div className="security-bottom-content">
          <div className="password-section">
            <label>Password</label>
            <input
              type="password"
              placeholder="********"
              value={currentPass}
              onChange={(e) => {
                setCurrentPassError(false);
                setCurrentPass(e.target.value);
              }}
              className="col-lg-9 col-md-10"
            />
            {currentPassError && (
              <span className="text-danger">Please enter current password</span>
            )}

            <a
              href="#"
              className="change-password-link"
              onClick={handleOpenPassModel}
            >
              Change password
            </a>
          </div>

          <div className="other-login-methods">
            <h3>Other login methods</h3>
            {/* <div className="parent-div-text"> */}
            <span className="login-google">Log in with Google</span>
            <p className="login-google-subtext">
              Connect your Google account for easy and quick logins
            </p>
            <button className="connect-google-button google-login-btn">
              <img src={Google} alt="Google Icon" />
              Connect Google
            </button>
            {/* </div> */}
          </div>
          <div className="bar"></div>

          <div className="logout-all-devices">
            <strong className="dark medium semi_bold d-block">
              Log out of all devices
            </strong>
            <p>
              Log out of all active sessions across all devices, including your
              current session. It may take up to 30 minutes for other devices to
              be logged out.
            </p>
            <button
              className="btn-style google-login-btn"
              onClick={() => d(userLogoutAsyncThunk())}
            >
              Log out of all
            </button>
          </div>
        </div>
        <div className="save-button-parent">
          <button className="btn-style">Save</button>
        </div>
      </div>
      {passModel && (
        <ChangePasswodModal
          currentPass={currentPass}
          isOpen={passModel}
          handleClose={handleClosePassModel}
        />
      )}
    </>
  );
}
