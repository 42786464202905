import { ErrorMessage, Field } from "formik";
import React from "react";

export default function JobDescriptionJobPostStep() {
  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper">
        <h3 className="step-title">Job description</h3>
      </div>
      <form action="#" className="form">
        <div className="row">
          <div className="col-md-12"></div>
          <div className="field-wrapper">
            <div className="form-group">
              <label htmlFor="title">
                What specific challenges or problems does your brand solve?
              </label>
              <Field
                as="textarea"
                className="form-control"
                placeholder="e.g Our supplements address health concerns like weak immune system and low eenergy "
                name="jobAttributes.painPoints"
              />

              <ErrorMessage
                component="span"
                className="error-msg"
                name="jobAttributes.painPoints"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">
                  What benefits does your product offer to customer?
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  placeholder="e.g Our supplements address health concerns like weak immune system and low eenergy  "
                  name="jobAttributes.benefits"
                />
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="jobAttributes.benefits"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">
                  Describe the type of cretor you’d like to work with
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  placeholder="e.g Our supplements address health concerns like weak immune system and low eenergy  "
                  name="jobAttributes.creatorType"
                />
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="jobAttributes.creatorType"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="field-wrapper">
              <div className="form-group">
                <label htmlFor="title">
                  What themes should creators focus on when promoting your
                  products?
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  placeholder="e.g Our supplements address health concerns like weak immune system and low eenergy  "
                  name="jobAttributes.contentStrategy"
                />
                <ErrorMessage
                  component="span"
                  className="error-msg"
                  name="jobAttributes.contentStrategy"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
