import React from "react";
import pointericon from "../assets/images/icons/pointer-icon.svg";
import { dateFormat } from "../utils/format";

export default function ReviewSettingsJobPostStep({ values, setActiveStep }) {
  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper has-icon mb-3 ">
        <h3 className="step-title">Settings</h3>
        <button className="edit-icon-wrapper" onClick={() => setActiveStep(2)}>
          <img src={pointericon} alt="pointericon" />
        </button>
      </div>
      <div className="content-wrapper">
        {/* <strong className="d-block dark medium semi_bold">
          Application deadline: {dateFormat(values?.deadlineDate)}
        </strong> */}
        {values?.isPrivate ? (
          <strong className="d-block dark medium semi_bold">
            Job set to be only visible to nudged creators
          </strong>
        ) : (
          <strong className="d-block dark medium semi_bold">
            Job is set to be visible to all creators.
          </strong>
        )}
      </div>
    </div>
  );
}
