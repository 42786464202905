import { ErrorMessage, Field } from "formik";
import React from "react";

export default function ApplicationSettingPostJobStep() {
  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper">
        <h3 className="step-title">Application Settings</h3>
      </div>
      <div className="content-wrapper-main">
        {/* <div className="text-wrapper">
          <strong className="dark medium-bold">Application deadline</strong>
          <span>All proposals must come in before the said date</span>
        </div> */}
        {/* <div className="date-wrapper">
          <form action="#" className="form">
            <div className="row">
              <div className="col-md-5">
                <div className="field-wrapper">
                  <div className="form-group">
                    <label htmlFor="#">Date</label>
                    <Field
                      type="date"
                      className="form-control"
                      placeholder={new Date().toISOString().split("T")[0]}
                      min={new Date().toISOString().split("T")[0]}
                      name="deadlineDate"
                    />
                  </div>
                  <ErrorMessage
                    component="span"
                    className="error-msg"
                    name="deadlineDate"
                  />
                </div>
              </div>
            </div>
          </form>
        </div> */}
        <div className="application-wrapper">
          <div className="row">
            <div className="col-md-7">
              <div className="text">
                <strong className="dark medium-bold">Application Privacy</strong>
                <span>
                Set this job to be private. Only invited creators can apply.
                </span>
              </div>
            </div>
            <div className="col-md-5">
              <div className="custom-toggle-switch-wrapper">
                <label class="switch">
                  {/* <input type="checkbox" /> */}
                  <Field type="checkbox" name="isPrivate" />
                  <span class="slider round"></span>
                </label>
              </div>
              <ErrorMessage
                component="span"
                className="error-msg"
                name="isPrivate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
