import React, { useEffect, useState } from "react";
import MyJobsPageHeader from "../components/MyJobsPageHeader";
import MyJobsListings from "../components/MyJobsListings";
import NoJobsComponent from "../components/NoJobsComponent";
import { useDispatch, useSelector } from "react-redux";
import { getHiredJobsAsyncThunk } from "../redux/pagesSlices/jobSlice";
import PaginationComponent from "../components/PaginationComponent";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import MyJob from "../components/MyJob";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import SearchIcon from "../assets/images/icons/search-icon-wrapper.svg";
import {
  getApplicationCreatorCountAsyncThunk,
  getHiredCreatorsAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import { setValueIfExist } from "../utils/utils";
import NoJobNewComponent from "../components/NoJobNewComponent";
export default function MyCreator() {
  const { brand } = useSelector((s) => s.auth);
  const [activeJobsTab, setActiveJobsTabs] = useState("all");
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const activeJobTabsHandler = (tab) => {
    setActiveJobsTabs(tab);
  };
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(
  //     getHiredCreatorsAsyncThunk({
  //       ...(search && { name: search }),
  //       sortBy,
  //     })
  //   );
  // }, [search,sortBy]);
  const hired = useSelector((s) => s.getHiredCreators);
  console.log("Hired", hired);

  console.log("brand", brand);
  const params = useSelector(
    (s) => s.job?.paramsForThunk?.getHiredJobsAsyncThunk
  );

  const counts = useSelector((s) => s.application?.creatorCounts);
  console.log("🚀 ~ MyCreator ~ counts:", counts);

  // useEffect(() => {
  //     dispatch(getHiredJobsAsyncThunk({ sortBy, populate: "brandId" }));
  // }, []);

  const filterLabels = {
    "createdAt:desc": "Newest First",
    "createdAt:asc": "Oldest First",
    "jobTitle:asc": "A-Z",
    "jobTitle:desc": "Z-A",
  };
  // search, status : activeJobsTab === "all" ? "approved" : activeJobsTab

  useEffect(() => {
    const params = setValueIfExist({
      sortBy,
      userName: search,
      ...(activeJobsTab === "Hired"
        ? { isHired: true }
        : activeJobsTab === "Fired"
        ? { isFired: true }
        : ""),
    });
    console.log("Params being sent:", params); // Debugging
    dispatch(
      getHiredCreatorsAsyncThunk({
        params: { ...params },
        limit: 12,
      })
    );
  }, [search, sortBy, activeJobsTab, params, dispatch]);
  useEffect(() => {
    const params = setValueIfExist({
      userName: search,
    });
    console.log('params', params)

    dispatch(getApplicationCreatorCountAsyncThunk({ params: { ...params } }));
  }, [dispatch, search]);
  return (
    <div className="inner-page-wrapper active m-0">
      <div className="my-creator-page">
        <MyJobsPageHeader
          search={search}
          sortBy={sortBy}
          handleSearchChange={(e) => setSearch(e.target.value)}
          title={"My Creators"}
          containerFluid={true}
          setSortBy={setSortBy}
          exLargeContainer={true}
        />
        {/* {hasListings ? ( */}
        <>
          <div className="inner-page-section">
            <div className="top-bar-wrapper">
              <div className="container ex-large">
                <div className="row">
                  <div className="col-md-7">
                    <ul className="tabs-wrapper">
                      <li>
                        <button
                          className={`custom-btn ${
                            activeJobsTab === "all" ? "active" : ""
                          }`}
                          onClick={() => activeJobTabsHandler("all")}
                        >
                          All <span className="num">{counts?.total || 0}</span>
                        </button>{" "}
                      </li>
                      {/* isHired
statusPending
total */}
                      <li>
                        <button
                          className={`custom-btn ${
                            activeJobsTab === "Hired" ? "active" : ""
                          }`}
                          onClick={() => activeJobTabsHandler("Hired")}
                        >
                          Hired
                          <span className="num">{counts?.isHired || 0}</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className={`custom-btn ${
                            activeJobsTab === "Fired" ? "active" : ""
                          }`}
                          onClick={() => activeJobTabsHandler("Fired")}
                        >
                          Fired{" "}
                          <span className="num">{counts?.isFired || 0}</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div className="right-menu-wrapper cusrtom-search-bar-wrapper">
                      <div className="search-bar-wrapper">
                        <img
                          src={SearchIcon}
                          alt="SearchIcon"
                          className="search-icon"
                        />
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          value={search}
                          type="text"
                          className="form-control"
                          placeholder="Search Jobs"
                        />
                      </div>
                      <div className="filter-and-view-wrapper">
                        <div className="sort-by-wrapper">
                          <strong>Sort by: </strong>
                          <DropdownButton
                            onSelect={setSortBy}
                            id={`dropdown-button-drop-down`}
                            drop={"down"}
                            variant="secondary"
                            title={filterLabels[sortBy]}
                          >
                            <Dropdown.Item eventKey="createdAt:desc">
                              Newest First
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="createdAt:asc">
                              Oldest First
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="jobTitle:asc">
                              A-Z
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="jobTitle:desc">
                              Z-A
                            </Dropdown.Item>
                          </DropdownButton>
                          {/* <img src={filterIcon} alt="filterIcon" className="filter-icon" /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <MyJobsListings tab={activeJobsTab} /> */}
            <div className="my-jobs-listings ">
              <div className="table-wrapper over_flow">
                <div className="my-proposals-listings">
                  <div className="table-wrapper">
                    <ProviderErrorLoadingPagination
                      reducer="application"
                      action="getHiredCreatorsAsyncThunk"
                      asyncThunk={getHiredCreatorsAsyncThunk}
                      dataKey="hiredCreators"
                      Component={MyJob}
                      emptyMessage="No applications found!"
                      emptyComponent={<NoJobNewComponent />}
                      pagination
                      Parent={({ children }) => (
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  textAlign: "start",
                                  paddingLeft: 133,
                                }}
                              >
                                <strong className="white small">
                                  Creator Name
                                </strong>
                              </th>
                              <th style={{ textAlign: "start" }}>
                                <strong className="white small">
                                  Job Status
                                </strong>
                              </th>

                              <th style={{ textAlign: "start" }}>
                                <strong className="white small">Product</strong>
                              </th>
                              <th style={{ textAlign: "start" }}>
                                <strong className="white small">
                                  Start date
                                </strong>
                              </th>
                              <th style={{ textAlign: "start" }}>
                                <strong className="white small">
                                  End Date
                                </strong>
                              </th>
                              <th style={{ textAlign: "start" }}>
                                <strong className="white small">Videos</strong>
                              </th>
                              <th style={{ textAlign: "start" }}>
                                <strong className="white small">Lives</strong>
                              </th>
                              <th style={{ textAlign: "start" }}>
                                <strong className="white small">
                                  GMV Gen.
                                </strong>
                              </th>
                              <th style={{ textAlign: "start" }}>
                                <strong className="white small">ROI</strong>
                              </th>
                              <th style={{ textAlign: "start" }}>
                                <strong className="white small"></strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>{children} </tbody>
                        </table>
                      )}
                      itemKey="application"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {/* ) : ( */}

        {/* <NoJobsComponent /> */}
        {/* )} */}

        {/* <PaginationComponent
          onItemsPerPageChange={handlePerPageChange}
          onPageChange={goToPage}
          initialItemsPerPage={jobs?.limit}
          initialPage={1}
          itemsPerPageOptions={[10, 20, 50]}
          totalItems={jobs?.totalResults}
        /> */}
      </div>
    </div>
  );
}
