import React, { useState } from "react";
import { Link } from "react-router-dom";
import JobsImg from "../assets/images/job-img-1.svg";
import { parseImagePathToURL } from "../utils/globalFunctions";
import smsIcon from "../assets/images/icons/sms-icon.svg";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import { dateFormat } from "../utils/format";
import { getId } from "../utils/utils";
export default function MyJob({ application }) {
  console.log("application", application);
  
  return (
    <tr>
      <td>
        <div className="main-wrapper">
          <div className="img-wrapper">
            <Link to={`/dashboard/creators/${getId(application)}?applicationId=${getId(application?.application)}`}>
              <img
                src={
                  application?.avatar
                    ? parseImagePathToURL(application?.avatar)
                    : JobsImg
                }
                alt="JobsImg"
              />
            </Link>
          </div>
          <div className="text-wrapper">
            <p className="dark">
              <Link to={`/dashboard/creators/${getId(application)}?applicationId=${getId(application?.application)}`}>{application?.userName}</Link>
            </p>
          </div>
        </div>
      </td>
      <td>
        <div className="applied-status rejected">
          <span className="icon"></span>
          <p className="dark">{application?.jobDetails?.status}</p>
        </div>
      </td>
      <td>
        <p className="dark">{application?.jobDetails?.jobTitle}</p>
      </td>
      <td><p className="dark">{dateFormat(application?.application?.hiredAt)}</p>  </td>
      <td><p className="dark">{dateFormat(application?.application?.completedAt)}</p>  </td>
      <td><p className="dark">3/{application?.application?.livePrice?.count ?? 0}</p>  </td>
      <td><p className="dark">4/{application?.application?.videoPrice?.count ?? 0}</p>  </td>
      <td><p className="dark">$281</p>  </td>
      <td><p className="dark">3X</p>  </td>
      <td style={{ textAlign: "right" }}>
        <Link to={`/dashboard/creators/${getId(application)}?applicationId=${getId(application?.application)}`}><img src={eyeIcon} alt="smsIcon" /></Link>
      </td>
    </tr>
  );
}
