import { ErrorMessage, FieldArray } from "formik";
import React from "react";
import { categoriesLabels } from "../utils/onBoardingCategorieslLabels";

export default function BrandFocusSignUpStep({ values }) {
  const contentArray = [
    // "⚡ Electronics",
    // "👗 Fashion",
    // "🍎 Beauty & Health",
    // "🏠 Home & Living",
    // "🎮 Toys & Games",
    // "🚗 Automotive",
    // "📷 Books & Media",
    // "🛒 Groceries & Food",
    // "🐶 Pet Supplies",
    // "💼 Office Supplies",
    // "🛠️ Tools & Hardware",
    // "💎 Jewellery & Watches",
    // "🏃🏻 Sports & Outdoors",
    // "👶🏻 Baby & Kids",
    // "🎨 Arts & Collectibles",
        "Electronics",
        "Fashion",
        "Beauty & Health",
        "Home & Living",
        "Toys & Games",
        "Automotive",
        "Books & Media",
        "Groceries & Food",
        "Pet Supplies",
        "Office Supplies",
        "Tools & Hardware",
        "Jewellery & Watches",
        "Sports & Outdoors",
        "Baby & Kids",
        "Arts & Collectibles",
  ];

  return (
    <div className="job-post-inner-wrapper">
      <div className="modal-text-wrapper">
        <h2>Brand Focus </h2>
        <p className="medium medium-bold">
          Select the categories that best represent your brand’s industry
        </p>
      </div>
      <div className="pick-content-list-wrapper">
        <FieldArray
          name="categories"
          render={(arrayHelpers) => (
            <ul className="pick-content-list">
              {contentArray.map((category, index) => (
                <li key={index}>
                  <label className="custom-radio">
                    <span>{categoriesLabels[category]}</span>
                    <input
                      type="checkbox"
                      name="categories"
                      value={category}
                      checked={values.categories.includes(category)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelpers.push(category); // Add to array
                        } else {
                          const idx = values.categories.indexOf(category);
                          arrayHelpers.remove(idx); // Remove from array
                        }
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              ))}
            </ul>
          )}
        />
      </div>
      <ErrorMessage component="span" className="error-msg" name="categories" />
    </div>
  );
}
