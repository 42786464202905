// import Image from 'next/image';
import emptyscreen from '../../assets/images/empty-img.png';
import ChatCIcon from '../../assets/images/icons/chat-icon-msg.svg'

export default function Emptyscreen(){
    return(
        <div className="no-chat-main-wrapper">
            <div className="img-wrapper">
                <img src={ChatCIcon} alt="ChatCIcon" style={{width : 56 , height:56}} />
            </div>
            <div className="content-wrapper">
                <p className='medium'>Select a chat to see your <br /> conversations here</p>
            </div>
        </div>
    )
}