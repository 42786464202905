import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import filterIcon from "../../assets/images/icons/filter-icon.svg";
import SearchIcon from "../../assets/images/icons/search-icon-wrapper.svg";
import CreatorCardComponent from "./CreatorCardComponent";
import { useDispatch, useSelector } from "react-redux";
import { getHiredCreatorsAsyncThunk, getJobApplicantsAsyncThunk } from "../../redux/pagesSlices/applicationSlice";
import ProviderErrorLoadingPagination from "../ProviderErrorLoadingPagination";
import NoJobNewComponent from "../NoJobNewComponent";
import { setValueIfExist } from "../../utils/utils";
import HiredCreatorCardComponent from "./HiredCreatorCardComponent"

export default function HiredCreators({ jobId,title }) {
  const applications = useSelector((s) => s.application.hiredCreators);
  const d = useDispatch();
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  // console.log("hired applications",applications);
  // console.log("jobId",jobId);
  
  
  // useEffect(() => {
  //   if (jobId)
  //     d(
  //       getHiredCreatorsAsyncThunk({
  //         // id: jobId,
  //         params: {
  //           populate: "creatorId",
  //         },
  //       })
  //     );   
  // }, [d, jobId]);
  useEffect(() => {
    const params = {
      ...setValueIfExist({
        userName: search,
        sortBy,
      }),
      populate: "creatorId",
    }
    setTimeout(() => {
      d(
        getHiredCreatorsAsyncThunk({
          // id: jobId,
          params: {...params},
        })
      );
    }, 500);
  }, [search, d, sortBy,jobId]);
  return (
    <div className="creator-applicant-section">
      <div className="row mb-4">
        <div className="col-md-6 align-self-center">
          <strong className="dark medium">
            {title === "Hired" ? "" : `Creator Applicants (${applications?.totalResults ?? 0})`}
            {/* Creator Applicants ({applications?.totalResults ?? 0}) */}
          </strong>
        </div>
        <div className="col-md-6 align-self-center">
          <div className="right-menu-wrapper cusrtom-search-bar-wrapper">
            <div className="search-bar-wrapper">
              <img src={SearchIcon} alt="SearchIcon" className="search-icon" />
              <input
                type="text"
                className="form-control"
                placeholder="Search Jobs"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="filter-and-view-wrapper">
              <div className="sort-by-wrapper">
                <strong>Sort by: </strong>
                <DropdownButton
                  id={`dropdown-button-drop-down`}
                  drop={"down"}
                  variant="secondary"
                  title={"Filter"}
                  onSelect={(e) => setSortBy(e)}
                >
                  <Dropdown.Item eventKey="createdAt:desc">
                    Newest First
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="createdAt:asc">
                    Oldest First
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="jobTitle:asc">A-Z</Dropdown.Item>
                  <Dropdown.Item eventKey="jobTitle:desc">Z-A</Dropdown.Item>
                </DropdownButton>
                <img
                  src={filterIcon}
                  alt="filterIcon"
                  className="filter-icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <ProviderErrorLoadingPagination
          reducer="application"
          action="getHiredCreatorsAsyncThunk"
          asyncThunk={getHiredCreatorsAsyncThunk}
          dataKey="jobApplicants"
          Component={HiredCreatorCardComponent}
          emptyMessage={
            <>
              <NoJobNewComponent />
            </>
          }
          componentProps={{
            className: "col-md-4",
          }}
          pagination
        />
        {/* map will apply from here  */}
      </div>
    </div>
  );
}
