import React, { useState } from "react";

import { motion, AnimatePresence } from "framer-motion";

import { FiArrowRight } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import JobPostStepperComponent from "../components/JobPostStepperComponent";
import BasicInfoJobPostStep from "../components/BasicInfoJobPostStep";
import JobDescriptionJobPostStep from "../components/JobDescriptionJobPostStep";
import ProductImageJobPostStep from "../components/ProductImageJobPostStep";
import VideoSamplesJobPostStep from "../components/VideoSamplesJobPostStep";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ApplicationSettingPostJobStep from "../components/ApplicationSettingPostJobStep";
import ReviewJobPostStep from "../components/ReviewJobPostStep";
import ReviewJobDescriptionPostJob from "../components/ReviewJobDescriptionPostJob";
import { useDispatch, useSelector } from "react-redux";
import { createJobAsyncThunk } from "../redux/pagesSlices/jobSlice";
import { useNavigate } from "react-router-dom";

const stepZeroSchema = Yup.object({
  jobTitle: Yup.string()
    .required("Job title is required")
    .max(150, "Job title cannot exceed 150 characters"),
  sellerShopCode: Yup.string().required("Seller shop code is required"),
  types: Yup.array()
    .of(
      Yup.string().oneOf(
        ["video", "live"],
        "Invalid type. Allowed types are 'video' or 'live'"
      )
    )
    .required("Types are required")
    .min(1, "At least one type must be selected"),
  ageRange: Yup.array()
    .of(
      Yup.string().oneOf(
        ["18-24", "24-35", "35-55", "55+"],
        "Invalid age range."
      )
    )
    .required("Age range required")
    .min(1, "At least one age range must be selected"),
  gender: Yup.string().required("Gender is required"),
  categories: Yup.array()
    .of(
      Yup.string().oneOf(
        [
          "Electronics",
          "Beauty & Health",
          "Home & Living",
          "Toys & Games",
          "Automotive",
          "Books & Media",
          "Groceries & Food",
          "Pet Supplies",
          "Office Supplies",
          "Tools & Hardware",
          "Jewellery & Watches",
          "Sports & Outdoors",
          "Baby & Kids",
          "Arts & Collectibles",
        ],
        "Invalid category."
      )
    )
    .required("Category is required")
    .min(1, "At least one category must be selected"),
  tiktokShopUrl: Yup.string()
    .url("Enter a valid URL")
    .required("TikTok shop URL is required")
    .matches(
      /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/,
      "URL must be a valid TikTok shop URL"
    ),
});

const stepOneSchema = Yup.object({
  jobAttributes: Yup.object({
    painPoints: Yup.string().required("Challenges or problems are required"),
    benefits: Yup.string().required("Benefits are required"),
    creatorType: Yup.string().required("Creator type is required"),
    contentStrategy: Yup.string().required("Content strategy is required"),
  }),
});

const stepTwoSchema = Yup.object({
  image: Yup.array()
    .of(
      Yup.mixed().test("fileFormat", "Unsupported file format", (value) => {
        if (!value) return true;
        const validFormats = [
          "image/png",
          "image/jpg",
          "image/jpeg",
          "image/webp",
        ];
        return validFormats.includes(value.type);
      })
    )
    .min(1, "At least one image is required")
    .max(4, "You can upload up to 4 images only.")
    .required("At least one image is required."),
  videoUrl: Yup.string()
    .test("validate-urls", "Each URL must be valid", (value) => {
      if (!value) return false; // Fails if the string is empty or null
      const urls = value.split(",").map((url) => url.trim());
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      return urls.every((url) => urlRegex.test(url)); // Validate each URL
    })
    .required("At least one video link is required"),
});

const stepThreeSchema = Yup.object({
  // deadlineDate: Yup.date().required("Deadline date is required"),
  isPrivate: Yup.boolean().required("Job type is required"),
});

export default function JobPostPage() {
  const [activeStep, setActiveStep] = useState(0);
  const { profile, user } = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep < 6) setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };
  const isActive = (step) => step === activeStep;
  const isCompleted = (step) => step < activeStep;

  const handleSubmit = async (values, { resetForm, errors }) => {
    const formData = new FormData();

    // Append simple key-value pairs
    formData.append("jobTitle", values.jobTitle);
    formData.append("sellerShopCode", values.sellerShopCode);
    formData.append("gender", values.gender);
    // formData.append("deadlineDate", values.deadlineDate);
    formData.append("isPrivate", values.isPrivate);
    formData.append("tiktokShopUrl", values.tiktokShopUrl);
    formData.append("videoUrl", values.videoUrl);

    // formData.append("image", values.image);
    formData.append("brandName", profile?.name);

    // Append arrays
    values.image.forEach((image) => formData.append("image", image));
    values.types.forEach((type) => formData.append("types[]", type));
    values.ageRange.forEach((age) => formData.append("ageRange[]", age));
    values.categories.forEach((category) =>
      formData.append("categories[]", category)
    );

    for (const key in values.jobAttributes) {
      if (values.jobAttributes.hasOwnProperty(key)) {
        formData.append(`jobAttributes[${key}]`, values.jobAttributes[key]);
      }
    }

    const contactInfo = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    };

    for (const key in contactInfo) {
      if (contactInfo.hasOwnProperty(key)) {
        formData.append(`contactInfo[${key}]`, contactInfo[key]);
      }
    }

    // Append brandId
    // formData.append("brandId", profile?._id || profile?.id);

    // Dispatch action
    dispatch(
      createJobAsyncThunk({
        data: formData,
        callBack: () => {
          navigate(`/dashboard`);
          resetForm();
        },
      })
    );
  };

  return (
    <div className="job-post-page">
      <Formik
        initialValues={{
          jobTitle: "",
          position: "",
          description: "",
          avatar: null,
          phone: "",
          website: "",
          categories: [],
          jobTitle: "",
          sellerShopCode: "",
          types: [],
          ageRange: [],
          gender: "",
          categories: [],
          tiktokShopUrl: "",
          jobAttributes: {
            painPoints: "",
            benefits: "",
            creatorType: "",
            contentStrategy: "",
          },
          image: [],
          videoUrl: "",
          // deadlineDate: "",
          isPrivate: false,
        }}
        validationSchema={
          activeStep === 0
            ? stepZeroSchema
            : activeStep === 1
            ? stepOneSchema
            : activeStep === 2
            ? stepTwoSchema
            : activeStep === 3
            ? stepThreeSchema
            : stepZeroSchema
        }
        onSubmit={activeStep === 4 ? handleSubmit : handleNext}
      >
        {({ isSubmitting, values, errors, setFieldValue, setFieldTouched }) => {
          console.log("values", values);
          return (
            <>
              <Form className="form">
                <JobPostStepperComponent
                  activeStep={activeStep}
                  isActive={isActive}
                  isCompleted={isCompleted}
                />
                <div className="job-post-steps-main-content-wrapper">
                  <div className="container ex-small">
                    <div className="inner-main-step-wrapper">
                      <motion.div layout className="step-content">
                        <AnimatePresence mode="wait">
                          <motion.div
                            key={activeStep}
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -100 }}
                            transition={{ duration: 0.5 }}
                          >
                            {activeStep === 0 && (
                              <>
                                <BasicInfoJobPostStep
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                />
                              </>
                            )}
                            {activeStep === 1 && (
                              <>
                                <JobDescriptionJobPostStep />
                              </>
                            )}
                            {activeStep === 2 && (
                              <>
                                <ProductImageJobPostStep
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                                <VideoSamplesJobPostStep
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              </>
                            )}
                            {activeStep === 3 && (
                              <>
                                <ApplicationSettingPostJobStep />
                              </>
                            )}
                            {activeStep === 4 && (
                              <>
                                <ReviewJobPostStep
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  setActiveStep={setActiveStep}
                                />
                              </>
                            )}
                          </motion.div>
                        </AnimatePresence>
                      </motion.div>
                    </div>
                    <div className="btn-wrapper">
                      {activeStep === 0 && (
                        <>
                          <span></span>
                        </>
                      )}
                      {activeStep !== 0 && (
                        <button
                          type="button"
                          className="btn-style transparent-border"
                          onClick={handleBack}
                          disabled={activeStep === 0}
                        >
                          <FiArrowLeft /> Previous
                        </button>
                      )}
                      {activeStep < 4 && (
                        <button
                          type="submit"
                          className="btn-style "
                          disabled={activeStep === 4}
                        >
                          Next <FiArrowRight />{" "}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
