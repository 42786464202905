import React from 'react'
import successIcon from "../../assets/images/icons/successIcon.svg"
import CloseIcon from "../../assets/images/icons/CloseIcon.svg"

const ChatSuccess = () => {
  return (
    <div className="error-messages-show-parent">
    <div className="error-messages-show">
        <div className="comp1">
            <div className="image-parent">
            <img src={successIcon} alt="errorIcon" />
            </div>
            <span>Chat deleted successfully!</span>
        </div>
        <div className="comp2">
            <div className="close-icon">
            <img src={CloseIcon} alt="CloseIcon" />
        </div>
        </div>
    </div>
    </div>
  )
}

export default ChatSuccess