import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import envelopIcon from "../assets/images/icons/envelop-icon.svg";
import notificationIcon from "../assets/images/icons/notification-icon.svg";
import avatarIcon from "../assets/images/icons/avatar-icon.svg";
import Logo from "../assets/images/icons/logo.svg";
import userLightIcon from "../assets/images/icons/user-light-icon.svg";
import settingsLightIcon from "../assets/images/icons/settings-light-icon.svg";
import helpLightIcon from "../assets/images/icons/help-light-icon.svg";
import logoutIcon from "../assets/images/icons/logout-light-icon.svg";
import shopIcon from "../assets/images/icons/shop-icon.svg";
import settingIcon from "../assets/images/icons/settings-light-icon.svg";
import xIcon from "../assets/images/icons/x-icon.svg";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { useDispatch, useSelector } from "react-redux";
import { TbLogout } from "react-icons/tb";
import {
  setBrand,
  setBrandProfile,
  storeProfile,
  userLogoutAsyncThunk,
} from "../redux/pagesSlices/authSlice";
import { Button } from "react-bootstrap";
import NotificationComponent from "./NotificationsComponent";
import { fetchRoomsAsyncThunk } from "../redux/pagesSlices/chatSlice";
import { getId, returnArray } from "../utils/utils";
import { IoAddCircleSharp } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import { getMyBrandsAsyncThunk } from "../redux/pagesSlices/brandSlice";

export default function DashboardHeader({ brands }) {
  const { profile, user, brand = {} } = useSelector((s) => s.auth);
  console.log("🚀 ~ DashboardHeader ~ brand:", brand);

  const notifications = useSelector((s) => s.chat.notifications);

  const d = useDispatch();
  const handleLogout = () => {
    d(userLogoutAsyncThunk());
  };
  // const [showNewBrandModel, setShowNewBrandModel] = useState(false);
  const [showNotification, setShowNotifications] = useState(false);
  const notificationRef = useRef(null);

  const showNotificationsHandler = () => {
    setShowNotifications(true);
  };
  const hideNotificationsHandler = () => {
    setShowNotifications(false);
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    d(fetchRoomsAsyncThunk());
    // dispatch(getSystemRoomAsyncThunk())
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleBrandSwitch = (selectedBrand) => {
    if (selectedBrand?.steps < 5) {
      const previousBrands = brand;
      localStorage.setItem("previous_space", JSON.stringify(previousBrands));
    }
    d(setBrand(selectedBrand));
    console.log("selectedBrand at switch function", selectedBrand);
  };

  useEffect(() => {
    d(getMyBrandsAsyncThunk()); // Fetch brands
  }, [d]);

  const handleAddNewBrand = () => {
    const previousBrands = brand;
    d(storeProfile(brand));
    d(setBrandProfile(null));

    localStorage.setItem("previous_space", JSON.stringify(previousBrands));
  };
  return (
    <header className="dashboard-header">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 align-self-center">
            <div className="logo-wrapper">
              <a href="#">
                <img src={Logo} alt="Logo" className="logo" />
              </a>
            </div>
            <div className="header-menu">
              <ul className="menu">
                <li>
                  <NavLink end to="/dashboard" activeClassName="active">
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink end to="/dashboard/my-jobs" activeClassName="active">
                    My Jobs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    end
                    to="/dashboard/creators"
                    activeClassName="active"
                  >
                    My Creators
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    end
                    to="/dashboard/find-creators"
                    activeClassName="active"
                  >
                    Find Creators
                  </NavLink>
                </li>
                <li>
                  <NavLink end to="/job-post" activeClassName="active">
                    Post a Job
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 align-self-center">
            <div className="right-menu">
              <ul className="menu">
                <li className="has-notification">
                  <NavLink to="/dashboard/messages" activeClassName="active">
                    <img src={envelopIcon} alt="envelopIcon" />
                  </NavLink>
                </li>
                <li
                  className={
                    returnArray(notifications)?.length > 0
                      ? "has-notification"
                      : ""
                  }
                >
                  <Link
                    to="#"
                    onClick={showNotificationsHandler}
                    className={`${showNotification ? "active" : ""}`}
                  >
                    <img src={notificationIcon} alt="notificationIcon" />
                  </Link>
                </li>
              </ul>
              <div className="avatar-dropdown-wrapper">
                <img
                  src={
                    profile?.avatar
                      ? parseImagePathToURL(profile?.avatar)
                      : avatarIcon
                  }
                  alt="avatarIcon"
                  className="avatar-img"
                />
                <DropdownButton
                  id={`dropdown-button-drop-down`}
                  drop={"down"}
                  variant="secondary"
                  className="custom-dropdown"
                  title={profile?.name}
                >
                  <div className="dropdown-title-wrapper">
                    {returnArray(brands?.results).map((_brand, index) => (
                      <div
                        key={index}
                        className="brand-ttile-wrapper"
                        onClick={() => handleBrandSwitch(_brand)}
                      >
                        <div className="img-wrapper">
                          <img
                            src={
                              _brand?.avatar
                                ? parseImagePathToURL(_brand?.avatar)
                                : avatarIcon
                            }
                            alt="brand"
                          />
                        </div>
                        <div className="text-wrapper">
                          <strong className="dark medium-bold">
                            {_brand?.name}
                          </strong>
                          {getId(brand) === getId(_brand) ? (
                            <FaCheckCircle className="verified-icon" />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
                    <div className="add-brand-main-wrapper">
                      <div className="add-brand-btn-wrrapper">
                        <button
                          className="blue trasparent-btn"
                          onClick={handleAddNewBrand}
                        >
                          <IoAddCircleSharp /> Add new brand
                        </button>
                      </div>
                    </div>
                  </div>
                  <Dropdown.Item
                    eventKey="1"
                    as={Link}
                    to="#"
                  >
                    <img src={shopIcon} alt="shopIcon" /> My brands{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="2"
                    as={Link}
                    to="/dashboard/my-profile"
                  >
                    <img src={userLightIcon} alt="userLightIcon" /> View brand
                    profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="3"
                    as={Link}
                    to="/dashboard/settings"
                  >
                    <img src={settingsLightIcon} alt="userLightIcon" /> Settings
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="4">
                    <img src={helpLightIcon} alt="userLightIcon" /> Help &
                    Support
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleLogout} eventKey="4">
                    <img src={logoutIcon} alt="userLightIcon" /> Log out
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationComponent
        showNotification={showNotification}
        hideNotificationsHandler={hideNotificationsHandler}
        notificationRef={notificationRef}
        notifications={notifications}
      />
    </header>
  );
}
