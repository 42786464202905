import React, { useState } from "react";

import uploadBrandLogoIcon from "../assets/images/icons/upload-brand-logo-icon.svg";
import { ErrorMessage, Field } from "formik";

export default function IntroduceBrandSignupStep2({ setFieldValue, values }) {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files; // Get the dragged files
    if (files.length > 0) {
      setFieldValue("avatar", files[0]); // Update Formik field value
    }
  };

  return (
    <div className="job-post-inner-wrapper">
      <div className="modal-text-wrapper">
        <h2>Introduce Your Brand</h2>
        <p className="medium medium-bold">
          Let’s get started with the basics so we can better support your goals.
        </p>
      </div>
      <div className="enter-brand-logo-wrapper">
        <label htmlFor="logo">Brand Logo</label>
        <div
          className={`upload-brand-logo-wrapper ${
            isDragging ? "dragging" : ""
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <label className="label" htmlFor="upload-brand">
            <input
              type="file"
              id="upload-brand"
              className="visibility-hidden"
              onChange={(e) => {
                setFieldValue("avatar", e.target.files[0]);
              }}
            />
            <div className="img-wrapper">
              <img
                src={uploadBrandLogoIcon}
                alt="uploadBrandLogoIcon"
                className="upload-icon"
              />
            </div>
            <div className="text-wrapper">
              <p className="dark_text">
                {isDragging
                  ? "Drop the file here"
                  : "Drop your image here, or "}
                <span className="blue">browse</span>
              </p>
              <span className="ex-small">Supports: PNG, JPG, JPEG, WEBP</span>
              <p className="m-0">
                <span className="blue" style={{ fontSize: "15px" }}>
                  {values?.avatar?.name}
                </span>
              </p>
            </div>
          </label>
        </div>
      </div>
      <div className="field-wrapper">
        <div className="form-group">
          <label htmlFor="email">Describe your brand</label>
          <Field
            as="textarea"
            className="form-control"
            placeholder="Tell us more about your brand"
            name="description"
          />
        </div>
        <ErrorMessage
          component="span"
          className="error-msg"
          name="description"
        />
      </div>
    </div>
  );
}
