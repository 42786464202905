import React, { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import * as Ably from "ably";
import { AblyProvider, ChannelProvider } from "ably/react";
import DashboardHeader from "../components/DashboardeHeader";
import { useDispatch, useSelector } from "react-redux";
import { getMyBrandsAsyncThunk } from "../redux/pagesSlices/brandSlice";
import { getId } from "../utils/utils";
import { setBrand } from "../redux/pagesSlices/authSlice";

export default function Dashboard() {
  const auth = useSelector((s) => s.auth);
  const brands = useSelector((s) => s.brand.myBrands);

  const d = useDispatch();
  const client = useMemo(
    () =>
      auth?.user?.id || auth?.user?._id
        ? new Ably.Realtime({
            key: process.env.REACT_APP_ABLY_API_KEY,
            clientId: auth?.profile?.id || auth?.profile?._id,
          })
        : null,
    [auth?.profile?._id, auth?.profile?.id]
  );

  useEffect(() => {
    d(getMyBrandsAsyncThunk());
  }, [d]);

  return (
    <div className="dashboard-page">
      <AblyProvider client={client}>
        <ChannelProvider channelName="notifications">
          <DashboardHeader brands={brands} />
          <Outlet key={getId(auth?.brand)} />
        </ChannelProvider>
      </AblyProvider>
    </div>
  );
}
