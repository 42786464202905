import { loadStripe, Stripe } from '@stripe/stripe-js';

let stripePromise

export const getStripe = () => {
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  if (!publishableKey) {
    console.error('Stripe publishable key is not set.');
    return Promise.resolve(null);
  }

  // if (!stripePromise) {
    stripePromise = loadStripe(publishableKey);
  // }

  return stripePromise;
};