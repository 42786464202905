"use client";

import { useEffect, useState } from "react";

export function useAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [creator, setCreator] = useState(null);
  const [brand, setBrand] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const Authorization = `Bearer ${localStorage.getItem("access-token")}`;
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/authenticate`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: Authorization,
          },
          // credentials: 'include',
        }
      );
      if (!res.ok) {
        setProfile(null);
        setUser(null);
        setCreator(null);
        setBrand(null);
        setLoading(false);
        return null;
      }

      const user = await res.json();
      console.log("user", user);
      if (user.user.role === "creator") {
        setProfile(user?.creator);
        setCreator(user?.creator);
      }
      if (user.user.role === "brand") {
        setProfile(user?.brand);
        setBrand(user?.brand);
      }
      setUser(user?.user);
      setLoading(false);
    };
    if (!user) {
      try {
        setLoading(true);
        getUser();
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  }, [user]);

  return { user, profile, creator, brand, loading };
}
