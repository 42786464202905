import React from "react";
import ChatCIcon from '../../assets/images/icons/chat-icon-msg.svg'

export default function NoChatComponent () {
    return(
        <div className="no-chat-main-wrapper">
            <div className="img-wrapper">
                <img src={ChatCIcon} alt="ChatCIcon" />
            </div>
            <div className="content-wrapper">
                <strong className="dark semi_bold">You don’t have any conversations</strong>
                <p>Once you start collaborating, your <br /> conversations will appear here.</p>
                <a href="#" className="btn-style rounded blue-border" >Searh project</a>
            </div>
        </div>
    )
}