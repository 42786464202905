import { ErrorMessage, Field } from "formik";
import React from "react";

export default function IntroduceBrandSignupStep() {
  return (
    <div className="job-post-inner-wrapper">
      <div className="modal-text-wrapper">
        <h2>Introduce Your Brand</h2>
        <p className="medium medium-bold">
          Let’s get started with the basics so we can better support your goals.
        </p>
      </div>
      <form action="" className="form">
        <div className="field-wrapper">
          <div className="form-group">
            <label htmlFor="email">Brand Name</label>
            <Field
              type="text"
              className="form-control"
              placeholder="Enter your brand name"
              name="name"
            />
          </div>
          <ErrorMessage component="span" className="error-msg" name="name" />
        </div>
        <div className="field-wrapper">
          <div className="form-group">
            <label htmlFor="email">Your position</label>
            <Field
              type="text"
              className="form-control"
              placeholder="What’s your position in this brand"
              name="position"
            />
          </div>
          <ErrorMessage
            component="span"
            className="error-msg"
            name="position"
          />
        </div>
      </form>
    </div>
  );
}
