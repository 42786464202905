import React from "react";
import pointericon from "../assets/images/icons/pointer-icon.svg";

export default function ReviewJobDescriptionPostJob({ values, setActiveStep }) {
  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper has-icon mb-0">
        <h3 className="step-title">Job Description</h3>
        <button className="edit-icon-wrapper" onClick={() => setActiveStep(0)}>
          <img src={pointericon} alt="pointericon" />
        </button>
      </div>
      <div className="description-main-wrapper">
        <div className="description-wrapper">
          <strong className="semi_bold dark">
            What specific challenges or problems does your brand solve?
          </strong>
          <p className="medium medium-bold">
            {values?.jobAttributes?.painPoints}
          </p>
        </div>
        <div className="description-wrapper">
          <strong className="semi_bold dark">
            What benefits does your product offer to customer?
          </strong>
          <p className="medium medium-bold">
            {values?.jobAttributes?.benefits}
          </p>
        </div>
        <div className="description-wrapper">
          <strong className="semi_bold dark">
            Describe the type of cretor you’d like to work with
          </strong>
          <p className="medium medium-bold">
            {values?.jobAttributes?.creatorType}
          </p>
        </div>
        <div className="description-wrapper">
          <strong className="semi_bold dark">
            What themes should creators focus on when promoting your products?
          </strong>
          <p className="medium medium-bold">
            {values?.jobAttributes?.contentStrategy}
          </p>
        </div>
      </div>
    </div>
  );
}
