import { Formik } from "formik";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateBrandAsyncThunk } from "../../redux/pagesSlices/brandSlice";
import { getId, returnArray } from "../../utils/utils";

export default function EditProfileInfoModal({
  editProfileModal,
  editProfileModalCloseHandler,
  brand,
}) {
  console.log("Brand details", brand);

  const companyName = brand?.name;
  const about = brand?.description;
  const website = brand?.website;
  const categories = brand?.categories;
  const socials = returnArray(brand?.socials);

  const dispatch = useDispatch();
  const handleBrandUpdate = async (values) => {
    console.log("values", values);
    const categoriesArray = values.categories
      .split(",")
      .map((item) => item.trim());
    const processedCategories = values.categories
      .split(",") // Split by commas
      .map((category) => category.trim()) // Remove extra whitespace
      .filter((category) => category);

    const allowedTypes = [
      "Facebook",
      "Instagram",
      "Twitter",
      "LinkedIn",
      "TikTok",
      "TikTokShop",
      "YouTube",
      "Telegram",
      "Portfolio",
    ];

    const processedSocials = values.socials.filter(
      (social) => social.url.trim() !== ""
    );

    await dispatch(
      updateBrandAsyncThunk({
        id: getId(brand),
        data: {
          name: values.name,
          description: values.description,
          website: values.website,
          categories: processedCategories,
          socials: processedSocials,
        },
      })
    );
  };

  return (
    <div className="edit-profile-info-modal-main-wrapper">
      <Formik
        initialValues={{
          name: companyName,
          description: about,
          website: website,
          categories: categories?.join(", ") || "",
          socials: [
            {
              type: "Facebook",
              url: socials?.find((s) => s.type === "Facebook")?.url || "",
            },
            {
              type: "YouTube",
              url: socials?.find((s) => s.type === "YouTube")?.url || "",
            },
            {
              type: "Instagram",
              url: socials?.find((s) => s.type === "Instagram")?.url || "",
            },
            {
              type: "TikTok",
              url: socials?.find((s) => s.type === "TikTok")?.url || "",
            },
          ],
        }}
        onSubmit={handleBrandUpdate}
      >
        {({ values, handleChange, handleBlur, errors, handleSubmit }) => (
          <Modal
            show={editProfileModal}
            onHide={editProfileModalCloseHandler}
            className="custom-modal edit-profile-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit company Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="body-first mb-5">
                <form action="#" className="form">
                  <div className="form-group">
                    <label htmlFor="name">Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={values.name}
                      name="name"
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      placeholder="Burger King"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">About</label>
                    <textarea
                      type="text"
                      value={values.description}
                      name="description"
                      onChange={handleChange("description")}
                      onBlur={handleBlur("description")}
                      className="form-control"
                      placeholder="Burger King"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Location</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Texas, United States"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Phone number</label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="+1 00 000 0000"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Website address</label>
                    <input
                      type="text"
                      name="website"
                      value={values.website}
                      onChange={handleChange("website")}
                      onBlur={handleBlur("website")}
                      className="form-control"
                      placeholder="burgetking.com"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Categories</label>
                    <textarea
                      type="text"
                      value={values.categories}
                      name="categories"
                      onChange={handleChange("categories")}
                      onBlur={handleBlur("categories")}
                      className="form-control"
                      placeholder="Burger King"
                    />
                  </div>
                  {values.socials.map((social, index) => (
                    <div key={index} className="form-group">
                      <label htmlFor={`social-${index}`}>{social.type}</label>
                      <input
                        type="text"
                        name={`socials[${index}].url`}
                        value={values.socials[index].url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        placeholder={`Enter ${social.type} URL`}
                      />
                    </div>
                  ))}
                  {/* <div className="form-group">
                    <label htmlFor="name">TikTok</label>
                    <input
                      name="socials.tiktok"
                      value={values.socials.tiktok}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      placeholder="https://www.tiktok.com/7286376671597300998"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Instagram</label>
                    <input
                      name="socials.instagram"
                      value={values.socials.instagram}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      placeholder="https://www.tiktok.com/7286376671597300998"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Facebook</label>
                    <input
                      name="socials.facebook"
                      value={values.socials.facebook}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      placeholder="https://www.tiktok.com/7286376671597300998"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">You tube</label>
                    <input
                      name="socials.youtube"
                      value={values.socials.youtube}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      className="form-control"
                      placeholder="https://www.tiktok.com/7286376671597300998"
                    />
                  </div> */}
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="cancel" onClick={editProfileModalCloseHandler}>
                Cancel
              </Button>
              <Button className="save" onClick={handleSubmit}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </div>
  );
}
