import React, { useEffect, useState } from "react";
import DashboardHeader from "../components/DashboardeHeader";
import MyJobsPageHeader from "../components/MyJobsPageHeader";
import SideBarComponent from "../components/SideBarComponent";
import TabsAndFilterBar from "../components/TabsAndFilterBar";
import FindCreatorCardComponent from "../components/Job/FindCreatorCardComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandApplicationsAsyncThunk,
  getBrandApplicationsCountAsyncThunk,
} from "../redux/pagesSlices/applicationSlice";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import NoJobNewComponent from "../components/NoJobNewComponent";
import CreatorTabsAndFilterBar from "../components/CreatorsTabsAndFilterBar";

export default function FindCreators({}) {
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [statusTabs, setStatusTabs] = useState("all");
  const handleSearchChange = (e) => setSearch(e.target.value);
  const [gender, setGender] = useState("all");
  const [selectedFilters, setSelectedFilters] = useState({
    promotionType: [],
    preferredGender: "All",
    AgeRange : [],
  });

  console.log("Selected Filters", selectedFilters.AgeRange);
  

  const dispatch = useDispatch();
  useEffect(() => {
    const params = {
      populate: "brandId",
      sortBy,
      ...(selectedFilters.promotionType?.length > 0 && {
        types: selectedFilters.promotionType,
      }),
      ...(selectedFilters.preferredGender !== "All" && {
        gender: selectedFilters.preferredGender.toLowerCase() || "All",
      }),
      ...(selectedFilters.AgeRange !== "All" && {
        age: selectedFilters.AgeRange || "All",
      }),
      ...(statusTabs === "favorite"
        ? { favorite: true }
        : statusTabs === "nudge"
        ? { isInvited: true }
        : {}),
      ...(search && { userName: search }),
      // ...(statusTabs && statusTabs !== "all" ? { status: statusTabs } : {}),
    };
    dispatch(getBrandApplicationsAsyncThunk(params));
  }, [statusTabs, selectedFilters, search, sortBy]);

  useEffect(() => {
    const params = {
      // ...(search && { name: search }),
    };
    dispatch(
      getBrandApplicationsCountAsyncThunk({
        params,
        callBack: () => {
          dispatch(getBrandApplicationsCountAsyncThunk({params}));
        },
      })
    );
  }, [dispatch]);

  const creatorsCount = useSelector((s) => s.application.creatorsCount);
  console.log("creatorsCount", creatorsCount);

  const brandApplications = useSelector((s) => s.application.brandApplicants);
  console.log("Brand Applications", brandApplications);

  const sideBarOpenHandler = () => {
    setSideBarOpen(!sideBarOpen);
  };

  return (
    <div className="main-inner-page-wrapper">
        <SideBarComponent
          sideBarOpen={sideBarOpen}
          sideBarOpenHandler={sideBarOpenHandler}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          clearAllFilters={() =>
            setSelectedFilters({
              categories: [],
              promotionType: [],
              status: "All",
              preferredGender : "All",
              AgeRange : []
            })
          }
          arrangementArray={[
            { name: "PromotionType", type: "multiple" },
            { name: "PreferredGender", type: "single" },
            { name : "AgeRange" , type : "single" }
          ]}
        />
      <div className={`inner-page-wrapper ${sideBarOpen ? "active" : ""}`}>
        <div className="find-creators-page">
          <MyJobsPageHeader
            // exLargeContainer={true}
            largeContainer={true}
            title={"All Creators"}
            showBtn={false}
            sortBy={sortBy}
            setSortBy={setSortBy}
            search={search}
            handleSearchChange={handleSearchChange}
          />
          <CreatorTabsAndFilterBar
            // exLargeContainer={true}
            sortBy={sortBy}
            setSortBy={setSortBy}
            statusTabs={statusTabs}
            setStatusTabs={setStatusTabs}
            search={search}
            handleSearchChange={handleSearchChange}
            largeContainer={true}
            creatorsCount={creatorsCount}
          />
          <div className="my-creators-section">
            <div className="container large">
              <div className="row">
                <ProviderErrorLoadingPagination
                  reducer="application"
                  action="getBrandApplicationsAsyncThunk"
                  asyncThunk={getBrandApplicationsAsyncThunk}
                  dataKey="brandApplicants"
                  Component={FindCreatorCardComponent}
                  emptyMessage={
                    <>
                      <NoJobNewComponent />
                    </>
                  }
                  componentProps={{
                    className: "col-md-4",
                  }}
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
