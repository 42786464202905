import React from "react";
import Modal from "react-bootstrap/Modal";

import beachImg from "../../assets/images/beach-img.png";
import emojiIcon from "../../assets/images/icons/emoji-icon.svg";

export default function SendPhotoModal({
  sendPhotoModal,
  sendPhotoModalCloseHandler,
  isSend = true,
  photoURL,
  handleSend,
  messageText,
  setMessageText,
  loading,
}) {
  return (
    <div className="send-photo-modal-wrapper">
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={sendPhotoModal}
        className="send-photo-modal custom-modal"
        onHide={sendPhotoModalCloseHandler}
      >
        <Modal.Header sendPhotoModalCloseHandler  closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Send Photo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="send-photo-main-wrapper">
            <img src={photoURL || beachImg} alt="beachImg" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="caption-wrapper">
            <div className="text-wrapper">
              <img src={emojiIcon} alt="emojiIcon" className="emoji" />
              <input
                type="text"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                className="form-control"
                placeholder="Add a caption"
              />
            </div>
          </div>
          {isSend && (
            <button
              disabled={loading}
              className="btn-style auto_width rounded"
              onClick={handleSend}
            >
              {loading ? "Sending..." : "Send"}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
