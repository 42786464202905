import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { LuEyeOff, LuEye } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { getMyAllJobsAsyncThunk } from "../../redux/pagesSlices/jobSlice";
import { getId, returnArray } from "../../utils/utils";
import { createInvitesFromJobAsyncThunk } from "../../redux/pagesSlices/invitationSlice";

const inviteValidationSchema = Yup.object().shape({
  jobIds: Yup.array()
    .of(Yup.string().required("Each job must be a valid string"))
    .min(1, "You must select at least one job")
    .required("Jobs field is required"),
});

export default function InviteCreatorModal({ creatorId, isOpen, handleClose }) {
  const { loadings } = useSelector((s) => s.auth);
  const { myAllJobs } = useSelector((s) => s.job);
  const d = useDispatch();

  useEffect(() => {
    d(getMyAllJobsAsyncThunk({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    d(
      createInvitesFromJobAsyncThunk({
        data: { ...values, creatorId },

        callBack: () => {
          handleClose();
          resetForm();
        },
      })
    );
  };

  return (
    <div className="change-password-modal-wrapper">
      <Modal
        show={isOpen}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal change-password-modal"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            jobIds: [],
          }}
          validationSchema={inviteValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, setFieldTouched }) => {
            return (
              <>
                <Form className="form">
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Select jobs you want to invite
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="modal-inner-wrapper">
                      <div className="col-md-12">
                        <div className="field-wrapper">
                          <div className="form-group">
                            <label htmlFor="jobIds">Select Job</label>
                            <Select
                              styles={{ backgroundColor: "orange" }}
                              id="jobIds"
                              name="jobIds"
                              isMulti
                              className="form-select shadow-none select-package"
                              options={returnArray(myAllJobs).map((job) => ({
                                label: job?.jobTitle,
                                value: getId(job),
                              }))}
                              value={values.jobIds.map((jobId) => {
                                const job = myAllJobs.find(
                                  (job) => getId(job) === jobId
                                );
                                return {
                                  label: job?.jobTitle || jobId,
                                  value: jobId,
                                };
                              })}
                              onChange={(selectedOptions) => {
                                const selectedValues = selectedOptions
                                  ? selectedOptions.map(
                                      (option) => option.value
                                    )
                                  : [];
                                setFieldValue("jobIds", selectedValues);
                              }}
                              onBlur={() => {
                                setFieldValue("jobIds", values.jobIds);
                                setFieldTouched("jobIds", true);
                              }}
                              classNamePrefix="react-select"
                            />
                          </div>
                          <ErrorMessage
                            component="span"
                            className="error-msg"
                            name="jobIds"
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn-style transparent"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={loadings?.createInvitesFromJobAsyncThunk}
                      className="btn-style"
                    >
                      {loadings?.createInvitesFromJobAsyncThunk ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </Modal.Footer>
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
