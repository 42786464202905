import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../detectError";
import { authenticateAsyncThunk, setBrand } from "./authSlice";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
const toast = { error: () => {}, success: () => {} };
// Start Brand Slices
///////////////////////////////////////////////////

export const getBrandsAsyncThunk = createAsyncThunk(
  "brand/getBrandsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getBrands(params);
    return response?.data;
  })
);
export const getMyBrandsAsyncThunk = createAsyncThunk(
  "brand/getMyBrandsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getMyBrands(params);
    return response?.data;
  })
);
export const getBrandsAnalyticsAsyncThunk = createAsyncThunk(
  "brand/getBrandsAnalyticsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getBrandsAnalytics();
    return response?.data;
  })
);

export const getBrandAsyncThunk = createAsyncThunk(
  "brand/getBrandAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getBrand(id);
    return response?.data;
  })
);

export const createBrandAsyncThunk = createAsyncThunk(
  "brand/createBrandAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    // const state = getState();
    const response = await ApiRequests.createBrand(data);
    if (response.status == 204) {
      toast.success("Brand Create Successfully!");
    }
    if (callBack) callBack();
    let params = {};
    let state1 = getState().listings;
    if (state1.search) params.name = state1.search;
    if (state1.order) params.sortBy = `name:${state1.order}`;
    dispatch(
      getBrandsAsyncThunk({ ...params, populate: "brand_id", role: "Brand" })
    );
    // dispatch(getBrandsByIdsAsyncThunk({ ...state.brands?.paramsForThunk?.getBrandsByIdsAsyncThunk}))
    return response?.data;
  })
);

export const updateBrandAsyncThunk = createAsyncThunk(
  "brand/updateBrandAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.updateBrand(id, data);
    if (response.status == 200) {
      toast.success("Brand Updated Successfully!");
    }
    if (callBack) callBack();
    dispatch(authenticateAsyncThunk());
    return response?.data;
  })
);
export const updateBrandImageAsyncThunk = createAsyncThunk(
  "brand/updateBrandImageAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.updateBrandImage(id, data);
    if (response.status == 200) {
      toast.success("Brand Updated Successfully!");
    }
    if (callBack) callBack();
    dispatch(authenticateAsyncThunk());
    return response?.data;
  })
);

export const updateBrandProfileAsyncThunk = createAsyncThunk(
  "brand/updateBrandProfileAsyncThunk",
  catchAsync(async ({ data, callback }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.updateBrandProfile(data);
    if (response.status == 200) {
      toast.success("Brand Added Successfully!");
    }
    dispatch(authenticateAsyncThunk());

    if (callback) callback();

    // let params = {};
    // let state1 = getState().listings;
    // if (state1.search) params.name = state1.search;
    // if (state1.order) params.sortBy = `name:${state1.order}`;
    // dispatch(
    //   getBrandsAsyncThunk({ ...params, populate: "brand_id", role: "Brand" })
    // );
    // dispatch(getBrandsByIdsAsyncThunk({ populate: "image,brand_id", ...state.brands?.paramsForThunk?.getBrandsByIdsAsyncThunk, page: 1 }))
    dispatch(setBrand(response?.data));
    return response?.data;
  })
);

export const deleteBrandAsyncThunk = createAsyncThunk(
  "brand/deleteBrandAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteBrand(id);
    if (response.status == 204) {
      toast.success("Brand Deleted Successfully!");
      let params = {};
      let state = getState().listings;
      if (state.search) params.name = state.search;
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(
        getBrandsAsyncThunk({ ...params, populate: "brand_id", role: "Brand" })
      );
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  brands: {
    page: 1,
    brands: [],
    totalPages: 1,
  },
  myBrands: {
    page: 1,
    brands: [],
    totalPages: 1,
  },
  brandsAnalytics: {
    approved: 0,
    applied: 0,
    interested: 0,
    pass: 0,
    agreements: 0,
    applications: 0,
    jobs: 0,
  },
  brandsCount: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  inviteBrand: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  brandExport: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  brandRole: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  brandsList: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  brand: null,
  assets: null,
  asset: null,
  listings: {
    page: 1,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const brandSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getBrandsAnalyticsAsyncThunk.fulfilled, (state, action) => {
        state.brandsAnalytics = action.payload;
      })
      .addCase(updateBrandAsyncThunk.fulfilled, (state, action) => {
        state.brand = action.payload;
      })
      .addCase(getBrandsAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.brands = {
            page: 1,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getBrandsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.brands = {
            ...action.payload,
            brands: state?.brands?.results.concat(action?.payload?.results),
          };
        } else {
          state.brands = action.payload;
        }
      })
      .addCase(getMyBrandsAsyncThunk.fulfilled, (state, action) => {
        state.myBrands = action.payload;
      })
      .addCase(getBrandAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.brand = {
            ...action.payload,
            results: state?.brand?.results.concat(action?.payload?.results),
          };
        } else {
          state.brand = action.payload;
        }
      })
      .addCase(deleteBrandAsyncThunk.fulfilled, (state, action) => {
        state.brands = {
          ...state.brands,
          totalResults: state.brands?.totalResults - 1,
          results: state.brands?.results.filter((e) => e.id != action.payload),
        };
        state.brandsCount = {
          ...state.brandsCount,
          totalResults: state.brandsCount?.totalResults - 1,
          results: state.brandsCount?.results.filter(
            (e) => e.id != action.payload
          ),
        };
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getBrandsAsyncThunk,
            getBrandAsyncThunk,
            deleteBrandAsyncThunk,
            createBrandAsyncThunk,
            updateBrandAsyncThunk,
            getBrandsAnalyticsAsyncThunk,
            updateBrandProfileAsyncThunk,
            updateBrandImageAsyncThunk
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default brandSlice.reducer;
export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  brandSlice.actions;
