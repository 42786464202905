import React from 'react'
import SignUpCreatorError from '../components/ErrorMessages/SignUpCreatorError'
import SignUpCreatorSuccess from '../components/ErrorMessages/SignUpCreatorSucces'
import ProfileCreatorSuccess from '../components/ErrorMessages/ProfileCreatorSuccess'
import UpdateProfileSuccess from '../components/ErrorMessages/UpdateProfileSuccess'
import JobTitleSuccess from '../components/ErrorMessages/JobTitleSuccess'
import ChatSuccess from '../components/ErrorMessages/ChatSuccess'
import UnreadMessage from '../components/ErrorMessages/UnreadMessage'
import ApplicationDetailSuccess from '../components/ErrorMessages/ApplicationDetailSuccess'

const ErrorMessagePage = () => {
  return (
    <>
        <SignUpCreatorError/>
        <SignUpCreatorSuccess/>
        <ProfileCreatorSuccess/>
        <UpdateProfileSuccess/>
        <JobTitleSuccess/>
        <ApplicationDetailSuccess/>
        <ChatSuccess/>
        <UnreadMessage/>
        </>
  )
}

export default ErrorMessagePage