import React, { useEffect, useState } from "react";
import pointericon from "../assets/images/icons/pointer-icon.svg";
import SampleProductImage from "./SampleProductImage";
import productImg from "../assets/images/product-img.svg";

export default function ReviewProductImagesJobPostStep({
  values,
  setFieldValue,
  setActiveStep,
}) {
  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    const newImagesURLs = values?.image?.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });

    Promise.all(newImagesURLs).then((imageURLs) => {
      setImageURLs(imageURLs);
    });
  }, [values.image]);

  const handleDelete = (index) => {
    const updatedImages = [...values.image];
    updatedImages.splice(index, 1);
    setFieldValue("image", updatedImages);

    const updatedImageURLs = [...imageURLs];
    updatedImageURLs.splice(index, 1);
    setImageURLs(updatedImageURLs);
  };

  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper has-icon mb-2">
        <h3 className="step-title">Product image</h3>
        <button className="edit-icon-wrapper" onClick={() => setActiveStep(1)}>
          <img src={pointericon} alt="pointericon" />
        </button>
      </div>
      <div className="sample-product-images-main">
        <div className="main-wrapper">
          <SampleProductImage
            handleDelete={handleDelete}
            sampleProductImage={imageURLs}
          />
        </div>
      </div>
    </div>
  );
}
