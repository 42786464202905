import React from "react"

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";import JobDetailFullSection from "../JobDetailFullSection";
;

export default function ViewJobDetailModal({ viewJobDetailModalCloseHandler, viewJobDetailModal, jobs }) {
    return (
        <div className="view-job-detil-modal-wrapper">
            <Modal
                show={viewJobDetailModal}
                onHide={viewJobDetailModalCloseHandler}
                className="view-job-detail-modal custom-modal"
                centered
            >
                <Modal.Header>
                    <Button
                        variant="close"
                        onClick={viewJobDetailModalCloseHandler}
                        aria-label="Close"
                        className="cancel-btn "
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="job-detail-modal-body-wrapper"> 
                        <JobDetailFullSection jobs={jobs} />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}