import React from "react";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

export default function RangeComponent() {
    return (
        <div className="range-slider-main-wrapper">
            <div className="title-wrapper">
                <strong className="dark semi_bold">$20</strong>
                <strong className="dark semi_bold">$800</strong>
            </div>
            <RangeSlider />
        </div>
    )
}