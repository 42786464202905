import React from "react";

import pointericon from "../assets/images/icons/pointer-icon.svg";
import videoFileIcon from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import { Link } from "react-router-dom";

export default function ReviewBasicInfo({ values, setActiveStep }) {
  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper has-icon mb-0">
        <h3 className="step-title">Basic Information</h3>
        <button className="edit-icon-wrapper" onClick={() => setActiveStep(-1)}>
          <img src={pointericon} alt="pointericon" />
        </button>
      </div>
      <div className="review-basic-info-main-wrapper ">
        <div className="main-menu-wrapper full">
          <div className="content-main">
            <span>Job title</span>
            <strong className="medium semi_bold dark">
              {values?.jobTitle}
            </strong>
          </div>
        </div>
        <div className="main-menu-wrapper">
          <div className="content-main">
            <span>Seller shop code</span>
            <strong className="medium semi_bold dark">
              {values?.sellerShopCode}
            </strong>
          </div>
          <div className="content-main">
            <span>Type of content</span>
            <ul className="content-type-listing">
              {values?.types?.map((item, index) => (
                <li key={index}>
                  <img src={videoFileIcon} alt="Icon" />
                  {item === "video" ? "Video" : "Live"}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="main-menu-wrapper">
          <div className="content-main">
            <span>Age range</span>
            <strong className="medium semi_bold dark">
              {values?.ageRange?.join(", ")}
            </strong>
          </div>
          <div className="content-main">
            <span>Gender </span>
            <strong className="medium semi_bold dark">
              {values?.gender === "male" ? "Male" : "Female"}
            </strong>
          </div>
        </div>
        <div className="main-menu-wrapper">
          <div className="content-main">
            <span>Categories</span>
            <ul className="categories-listings">
              {values?.categories?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="content-main">
            <span>Tiktok shop link</span>
            <strong className="medium semi_bold dark link">
              <Link target="_blank" to={values?.tiktokShopUrl}>
                {values?.tiktokShopUrl}
              </Link>
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
}
