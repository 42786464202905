import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import {
  authenticateAsyncThunk,
  setBrandProfile,
} from "../redux/pagesSlices/authSlice";
import LoaderComponent from "../components/LoaderComponent";
import BrandSignupStepperModal from "../components/BrandSignupStepperModal";
import { useAuth } from "../hooks/useAuth";
import { getMyBrandsAsyncThunk } from "../redux/pagesSlices/brandSlice";

export default function Authenticated({ Component }) {
  const d = useDispatch();
  const { user: userAuth } = useAuth();
  const { myBrands } = useSelector((s) => s.brand);
  const auth = useSelector((s) => s.auth);
  const loading = auth?.loadings?.authenticateAsyncThunk;
  const user = auth?.user;
  const brand = auth?.brand;
  const isScreenLock = auth?.isScreenLock;
  const location = useLocation();
  const [hasMoreThanOne, setHasMoreThanOne] = useState(false);

  useEffect(() => {
    if (!user) d(authenticateAsyncThunk());
    else d(getMyBrandsAsyncThunk());
  }, [user, d]);

  useEffect(() => {
    setHasMoreThanOne(false);
  }, [brand]);

  if (loading) return <LoaderComponent />;

  if (!user) return <Navigate to="/sign-in" />;

  if (userAuth?.role === "creator" && location.pathname !== "/restricted") {
    return <Navigate to="/restricted" />;
  }
  const handleHideModal = () => {
    if (myBrands?.totalResults > 0) {
      const previousBrands = JSON.parse(localStorage.getItem("previous_space"));
      if (previousBrands) {
        d(setBrandProfile(previousBrands));
        localStorage.removeItem("previous_space");
        setHasMoreThanOne(true);
      } else {
        if (brand?.steps >= 5) {
          setHasMoreThanOne(true);
        }
      }
    }
  };

  const isProfileCompleted = Number(brand?.steps) >= 5;

  if (
    (!isProfileCompleted || isScreenLock) &&
    !hasMoreThanOne &&
    location.pathname !== "/restricted"
  ) {
    return (
      <div>
        <BrandSignupStepperModal
          show={!isProfileCompleted || isScreenLock}
          onHide={handleHideModal}
          modalCloseHandler={() => {}}
        />
      </div>
    );
  }

  return <Component />;
}
