import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { MdOutlineAttachment } from "react-icons/md";
import LinkTrackingUrlModal from "./LinkTrackingUrlModal";
import { FaEnvelope } from "react-icons/fa6";
import confirmPayment from "../assets/images/icons/subtract-icon.svg";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import videofile from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import { TbRefresh } from "react-icons/tb";
import { HiMiniInformationCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import proposalImg from "../assets/images/proposal-img.svg";
import dollarSign from "../assets/images/icons/dollar-sign.svg";
import regroupIcon from "../assets/images/icons/regroup-icon.svg";

export default function TrackJobsStepsContent({
  proposalDetails
}) {
  const [editModalState, setEditModalState] = useState(false);

  const editModalOpenHandler = () => {
    setEditModalState(true);
  };
  const editModalCloseHandler = () => {
    setEditModalState(false);
  };

  console.log("Proposal Details", proposalDetails);

  const accordionData = [
    {
      eventKey: "0",
      title: "Project Alignment & Requirements Finalized",
      description:
        "Engage with the creator to discuss project expectations, content guidelines, and deadlines. Ensure all requirements are clearly defined and agreed upon before production begins.",
      action: (
        <button className="custon-btn light" onClick={editModalOpenHandler}>
          <MdOutlineAttachment /> Message Elizabeth
        </button>
      ),
    },
    {
      eventKey: "1",
      title: "Payment Status",
      description:
        "Confirm payment transfer to the creator.",
      action: (
        <Link to={`/dashboard/messages?id=${proposalDetails?.brandId?.id}&jobId=${proposalDetails?.id || proposalDetails?._id}`} className="attatch-link-wrapper">
          <button className="custon-btn light">
            <img src={confirmPayment} alt="confirmPayment" />  Confirm payment sent
          </button>
        </Link>
      ),
    },
    {
      eventKey: "2",
      title: "Sample Shipment Status",
      description:
        "Confirm samples have been shipped to creator",
      action: (
        <button className="custon-btn light">
          <img src={confirmPayment} alt="confirmPayment" /> Confirm samples shipped
        </button>
      ),
    },
    {
      eventKey: "3",
      title: "Content Creation in Progress",
      description:
        "Follow the creator’s progress and receive regular updates.",
      action: (
        <>
          <div className="content-creation-steps-main">
            <div className="row">
              <div className="col-md-4 mb-5">
                <div className="image-side-text-parent">
                  <div className="img-wrapper">
                  <img src={proposalImg} alt="png img" />
                  </div>
                  <div className="text-icon-parent">
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={regroupIcon} alt="Regrouplight icon" />
                        <span className="roi-text">ROI</span>
                      </div>
                      <span className="text-3x">3x</span>
                    </div>
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={dollarSign} alt="dollarSign" />
                        <span className="roi-text">GMV</span>
                      </div>
                      <span className="text-3x">$89</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="image-side-text-parent">
                  <div className="img-wrapper">
                  <img src={proposalImg} alt="png img" />
                  </div>
                  <div className="text-icon-parent">
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={regroupIcon} alt="Regrouplight icon" />
                        <span className="roi-text">ROI</span>
                      </div>
                      <span className="text-3x">3x</span>
                    </div>
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={dollarSign} alt="dollarSign" />
                        <span className="roi-text">GMV</span>
                      </div>
                      <span className="text-3x">$89</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="image-side-text-parent">
                  <div className="img-wrapper">
                  <img src={proposalImg} alt="png img" />
                  </div>
                  <div className="text-icon-parent">
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={regroupIcon} alt="Regrouplight icon" />
                        <span className="roi-text">ROI</span>
                      </div>
                      <span className="text-3x">3x</span>
                    </div>
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={dollarSign} alt="dollarSign" />
                        <span className="roi-text">GMV</span>
                      </div>
                      <span className="text-3x">$89</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="image-side-text-parent">
                  <div className="img-wrapper">
                  <img src={proposalImg} alt="png img" />
                  </div>
                  <div className="text-icon-parent">
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={regroupIcon} alt="Regrouplight icon" />
                        <span className="roi-text">ROI</span>
                      </div>
                      <span className="text-3x">3x</span>
                    </div>
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={dollarSign} alt="dollarSign" />
                        <span className="roi-text">GMV</span>
                      </div>
                      <span className="text-3x">$89</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-5">
                <div className="image-side-text-parent">
                  <div className="img-wrapper">
                  <img src={proposalImg} alt="png img" />
                  </div>
                  <div className="text-icon-parent">
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={regroupIcon} alt="Regrouplight icon" />
                        <span className="roi-text">ROI</span>
                      </div>
                      <span className="text-3x">3x</span>
                    </div>
                    <div className="text-icon">
                      <div className="inner-text-icon">
                        <img src={dollarSign} alt="dollarSign" />
                        <span className="roi-text">GMV</span>
                      </div>
                      <span className="text-3x">$89</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </>
      ),
    },
    {
      eventKey: "4",
      title: "Finalise or Renew Contract",
      description: "Complete the project or initiate a renewal based on the campaign’s performance metrics (like GMV targets).",
      action: (
        <>
          <div className="progress-wrapper-main mt-5">
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar className="video-created-progress-bar blue" value={20} strokeWidth={13} />
                <strong className="total medium">5/5</strong>
              </div>
              <div className="text-wrapper">
                <h3>Videos Created</h3>
                <ul className="list-wrapper">
                  <li><span className="label gray"></span> Expected Videos</li>
                  <li><span className="label blue"></span> Videos Created</li>
                </ul>
              </div>
            </div>
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar className="video-created-progress-bar pink" value={20} strokeWidth={13} />
                <strong className="total medium">5/5</strong>
              </div>
              <div className="text-wrapper">
                <h3>Videos Created</h3>
                <ul className="list-wrapper">
                  <li><span className="label gray"></span> Expected Videos</li>
                  <li><span className="label pink"></span> Videos Created</li>
                </ul>
              </div>
            </div>
            <div className="progress-wrapper">
              <div className="circular-progress-wrapper">
                <CircularProgressbar className="video-created-progress-bar gradient" value={20} strokeWidth={13} />
                <strong className="total medium">$87</strong>
              </div>
              <div className="text-wrapper">
                <h3>Videos Created</h3>
                <ul className="list-wrapper">
                  <li><span className="label gray"></span> Expected Videos</li>
                  <li><span className="label gradient"></span> Videos Created</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="notification-wrapper">
            <HiMiniInformationCircle />
            <strong>
              You set this job to auto-renew if Elizabeth meets expected GMV
            </strong>
          </div>
          <div className="attatch-link-wrapper">
            <button className="btn-style error-btn">
              <RxCross2 /> Cancel auto-renewal
            </button>
          </div>
        </>
      ),
    },
  ];

  console.log("Proposal Details", proposalDetails);

  console.log("Status", proposalDetails?.status);

  const getClassName = (accordion) => {
    const status = proposalDetails?.status;
    const stepStatusMap = {
      0:
        status === "applied" ||
          status === "interested" ||
          status === "pass" ||
          status === "approved" ||
          status === "ended"
          ? "accordion-item complete"
          : "accordion-item active",
      1:
        status === "applied" ||
          status === "interested" ||
          status === "pass" ||
          status === "approved" ||
          status === "ended"
          ? "accordion-item complete"
          : "accordion-item active",
      2:
        status === "approved" || status === "pass" || status === "ended"
          ? "accordion-item complete"
          : status === "interested"
            ? "accordion-item active"
            : "accordion-item active",
      3:
        status === "ended" || status === "pass" || status === "approved"
          ? "accordion-item complete"
          : "accordion-item",
      4:
        status === "ended"
          ? "accordion-item complete"
          : status === "pass"
            ? "accordion-item complete"
            : "accordion-item active",
      5:
        status === "ended"
          ? "accordion-item complete"
          : status === "pass" ||
            status === "approved" ||
            status === "interested"
            ? "accordion-item "
            : "accordion-item active",
    };
    return stepStatusMap[accordion.eventKey] || "accordion-item";
  };

  return (
    <div className="track-job-steps-content-wrapper">
      <Accordion
        defaultActiveKey={
          accordionData.find((step) => getClassName(step).includes("active"))
            ?.eventKey
        }
      >
        {accordionData.map((item, index) => (
          <Accordion.Item
            key={item.eventKey}
            eventKey={item.eventKey}
            className={getClassName(item)}
          >
            <Accordion.Header>
              <div className="step-wrapper">
                <div className="count-wrapper">
                  {getClassName(item).includes("complete") ? (
                    <FaCheck />
                  ) : (
                    <span>{item.eventKey}</span>
                  )}
                </div>
                <div className="contetn-wrapper">
                  <strong className="medium">{item.title}</strong>
                  <p>{item.description}</p>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="attatch-link-wrapper">{item.action}</div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      <LinkTrackingUrlModal
        editModalState={editModalState}
        editModalCloseHandler={editModalCloseHandler}
        proposalDetails={proposalDetails}
      />
    </div>
  );
}
