import React from "react";
import Modal from 'react-bootstrap/Modal';

export default function ReportContactModal({ reportContactModalCloseHandler, reportContactModalState }) {

    const reportReasons = [
        "Harassment or Bullying",
        "Inappropriate Content  ",
        "Spam or Scams",
        "Hate Speech or Discrimination",
        "Misinformation",
        "Privacy Violations",
        "Impersonation",
        "Unwanted Contact",
    ];

    return (
        <div className="report-contact-modal-wrapper">
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={reportContactModalState}
                className="report-contact-modal custom-modal"
                onHide={reportContactModalCloseHandler}
            >
                <Modal.Header reportContactModalCloseHandler>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="inner-wrapper">

                        <div className="main-wrappr mb-4 mt-1">
                            <div className="text-start">
                                <h3> Report contact</h3>
                            </div>
                        </div>
                        <form action="#" className="form">
                            {reportReasons.map((reason, index) => (
                                <div className="form-group" key={index}>
                                    <div className="custom-radio-check-wrapper">
                                        <label className="custom-radio left">
                                            <input type="radio" className="radio-input" name="reason" />
                                            <span className="checkmark"></span>
                                            <span className="text dark medium medium-bold">{reason}</span>
                                        </label>
                                    </div>
                                </div>
                            ))}

                            <div className="field-wrapper mt-2">
                                <div className="form-group">
                                    <label htmlFor="description">Tell us more about it</label>
                                    <textarea name="description" id="description" className="form-control" placeholder="Describe your content"></textarea>
                                </div>
                            </div>
                            <div className="custom-checkbox-wrapper mt-2">
                                <label className="checkbox-label">
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                    Report and Block contact.
                                </label>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                        <a href="#" className="blue" onClick={reportContactModalCloseHandler}>Cancel</a>
                        <a href="#" className="btn-style rounded">Submit</a>
                </Modal.Footer>
            </Modal>
        </div>
    )
}