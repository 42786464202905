import React from "react";
import ReviewBasicInfo from "./ReviewBasicInfo";
import ReviewJobDescriptionPostJob from "./ReviewJobDescriptionPostJob";
import ReviewProductImagesJobPostStep from "./ReviewProductImagesJobPostStep";
import ReviewVideoSamplesJobPostStep from "./ReviewVideoSamplesJobPostStep";
import ReviewSettingsJobPostStep from "./ReviewSettingsJobPostStep";

export default function ReviewJobPostStep({
  values,
  setFieldValue,
  setActiveStep,
}) {
  return (
    <div className="review-job-post-main-wrapper">
      <ReviewBasicInfo setActiveStep={setActiveStep} values={values} />
      <ReviewJobDescriptionPostJob
        setActiveStep={setActiveStep}
        values={values}
      />
      <ReviewProductImagesJobPostStep
        values={values}
        setActiveStep={setActiveStep}
        setFieldValue={setFieldValue}
      />
      <ReviewVideoSamplesJobPostStep
        setActiveStep={setActiveStep}
        values={values}
      />
      <ReviewSettingsJobPostStep
        setActiveStep={setActiveStep}
        values={values}
      />
    </div>
  );
}
