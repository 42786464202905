import React from "react";

import enVelopIcon from '../assets/images/icons/envelop-fill-icon.svg'
import { Link } from "react-router-dom";
import LogoTopBar from "../components/LogoTopBar";
import LoginAsBrandBottomBar from "../components/LoginAsBrandBottomBar";

export default function EmailSentTemplate() {
    return (
        <div className="email-sent-template-page">
            <div className="inner-main-page">
                <LogoTopBar />
            </div>
            <div className="inner-wrapper">
                <div className="main-wrapper text-center">
                    <div className="img-wrapper">
                        <img src={enVelopIcon} alt="enVelopIcon" />
                    </div>
                    <div className="content-wrapper">
                        <h2>Email Sent!</h2>
                        <p className="dark_text">We’ve sent you the link to reset your password. If it doesent <br /> show up soon, check your spam folder.</p>
                    </div>
                    <div className="btn-wrapper">
                        <Link to="#" className="btn-style full large_font">Return to log in</Link>
                    </div>
                </div>
            </div>
            <LoginAsBrandBottomBar />
        </div>
    )
}