import React, { useEffect, useState } from "react";
import uploadBrandLogoIcon from "../assets/images/icons/upload-brand-logo-icon.svg";
import SampleProductImage from "./SampleProductImage";
import { IoMdAdd } from "react-icons/io";
import { ErrorMessage } from "formik";
import { toast } from "react-toastify";

export default function ProductImageJobPostStep({ values, setFieldValue }) {
  const [imageURLs, setImageURLs] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const newImagesURLs = values?.image?.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });

    Promise.all(newImagesURLs).then((imageURLs) => {
      setImageURLs(imageURLs);
    });
  }, [values.image]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    addFiles(files);
  };

  const handleDelete = (index) => {
    const updatedImages = [...values.image];
    updatedImages.splice(index, 1);
    setFieldValue("image", updatedImages);

    const updatedImageURLs = [...imageURLs];
    updatedImageURLs.splice(index, 1);
    setImageURLs(updatedImageURLs);
  };

  const addFiles = (files) => {
    if (files.length + values.image.length > 4) {
      toast.error("You can upload up to 4 images only.");
      return;
    }

    const newImageFiles = [...values.image, ...files];
    setFieldValue("image", newImageFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const files = Array.from(event.dataTransfer.files);
    addFiles(files);
  };

  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper">
        <h3 className="step-title">Product Image</h3>
      </div>
      <form
        action="#"
        className={`form ${isDragging ? "dragging" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="field-wrapper">
          <div className="form-group">
            <label htmlFor="uploadImage">
              Upload up to four clear product images
            </label>
            <div className="upload-brand-logo-wrapper">
              <label className="label" htmlFor="upload-brand">
                <input
                  type="file"
                  id="upload-brand"
                  className="visibility-hidden"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  multiple
                  onChange={(e) => handleFileChange(e)}
                />
                <div className="img-wrapper">
                  <img
                    src={uploadBrandLogoIcon}
                    alt="uploadBrandLogoIcon"
                    className="upload-icon"
                  />
                </div>
                <div className="text-wrapper">
                  <p className="dark_text">
                    Drop your image here, or{" "}
                    <span className="blue">browse</span>
                  </p>
                  <span className="ex-small">
                    Supports: PNG, JPG, JPEG, WEBP
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </form>
      <div className="sample-product-images-main">
        <div className="main-wrapper">
          <SampleProductImage
            sampleProductImage={imageURLs}
            handleDelete={handleDelete}
          />

          {/* Show upload button if there are fewer than 4 images */}
          {values.image.length > 0 && values.image.length < 4 && (
            <div className="upload-image-wrapper">
              <label className="label" htmlFor="upload-brand">
                <IoMdAdd />
              </label>
            </div>
          )}
        </div>
      </div>
      <ErrorMessage name="image" component="div" className="error-msg" />
    </div>
  );
}
