import React, { useState } from "react";
import pointericon from "../assets/images/icons/pointer-icon.svg";
import { FaPlay } from "react-icons/fa";
import videoMediaImg from "../assets/images/video-media-img.svg";

export default function ReviewVideoSamplesJobPostStep({
  values,
  setActiveStep,
}) {
  // Split the video URLs from the string
  const videoUrls = values.videoUrl
    ? values.videoUrl.split(",").map((url) => url.trim())
    : [];

  // State to manage visibility of play buttons for each video
  const [showPlayButton, setShowPlayButton] = useState(
    Array(videoUrls.length).fill(true)
  );

  const handlePlay = (index) => {
    // Pause all videos except the current one
    videoUrls.forEach((_, idx) => {
      const videoElement = document.getElementById(`video-${idx}`);
      if (videoElement && idx !== index) {
        videoElement.pause();
      }
    });

    // Hide play button for the current video
    setShowPlayButton((prev) =>
      prev.map((_, idx) => (idx === index ? false : true))
    );
  };

  const handlePause = (index) => {
    // Show play button for the current video
    setShowPlayButton((prev) =>
      prev.map((_, idx) => (idx === index ? true : prev[idx]))
    );
  };

  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper has-icon mb-4">
        <h3 className="step-title">Video samples</h3>
        <button className="edit-icon-wrapper" onClick={() => setActiveStep(1)}>
          <img src={pointericon} alt="Edit icon" />
        </button>
      </div>
      <div className="sample-product-videos-listing">
        <ul className="sample-videos-list">
          {videoUrls.map((url, index) => (
            <li key={index} style={{ position: "relative" }}>
              <video
                id={`video-${index}`}
                controls
                width="600"
                poster={videoMediaImg}
                onPlay={() => handlePlay(index)}
                onPause={() => handlePause(index)}
              >
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {showPlayButton[index] && (
                <div
                  className="icon-wrapper"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const videoElement = document.getElementById(
                      `video-${index}`
                    );
                    if (videoElement) videoElement.play();
                  }}
                >
                  <FaPlay />
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
