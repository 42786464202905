import React, { useEffect, useState } from "react";

import MyJobsPageHeader from "../components/MyJobsPageHeader";
import JobCardComponent from "../components/JobCardComponent";
import JobCardComponentListView from "../components/JobCardComponentListView";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import {
  getAvailableJobsAsyncThunk,
  getJobCountsAsyncThunk,
  getMyJobsAsyncThunk,
} from "../redux/pagesSlices/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import SideBarComponent from "../components/SideBarComponent";
import TabsAndFilterBar from "../components/TabsAndFilterBar";
import NoJobNewComponent from "../components/NoJobNewComponent";

export default function MyJobsPage() {
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    categories: [],
    promotionType: [],
    preferredGender: "All",
  });

  const [viewMode, setViewMode] = useState("grid");
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [statusTabs, setStatusTabs] = useState("approved");

  // Search and filter handlers
  const handleSearchChange = (e) => setSearch(e.target.value);

  const sideBarOpenHandler = () => {
    setSideBarOpen(!sideBarOpen);
  };

  useEffect(() => {
    dispatch(getJobCountsAsyncThunk({ ...(search && { jobTitle: search }) }));
  }, [search]);

  useEffect(() => {
    // pending, approved, rejected
    dispatch(
      getMyJobsAsyncThunk({
        ...(statusTabs && statusTabs !== "all" ? { status: statusTabs } : {}),
        ...(search && { jobTitle: search }),
        populate: "brandId",
        sortBy,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusTabs, search, sortBy]);

  // Fetch jobs based on filter changes
  // useEffect(() => {
  //   const fetchParams = {
  //     sortBy,
  //     ...(search && { jobTitle: search }),
  //     // ...(promotionType && { promotionType }),
  //     ...(selectedFilters.promotionType?.length > 0 && {
  //       types: selectedFilters.promotionType,
  //     }),
  //     ...(selectedFilters.preferredGender &&
  //       selectedFilters.preferredGender !== "All" && {
  //         gender: selectedFilters.preferredGender.toLowerCase(),
  //       }),
  //     ...(selectedFilters.categories?.length > 0 && {
  //       categories: selectedFilters.categories.filter((item) => item !== "All"),
  //     }),
  //     populate: "brandId",
  //   };

  //   const timeout = setTimeout(() => {
  //     dispatch(getAvailableJobsAsyncThunk(fetchParams));
  //   }, 500);

  //   return () => clearTimeout(timeout);
  // }, [dispatch, sortBy, search, selectedFilters]);


  return (
    <div className="main-inner-page-wrapper">
      <div className="inner-page-wrapper active m-0">
        <div className="my-jobs-page">
          <MyJobsPageHeader
            sortBy={sortBy}
            setSortBy={setSortBy}
            search={search}
            handleSearchChange={handleSearchChange}
            jobsViewHandler={setViewMode}
            jobsView={viewMode}
            // exLargeContainer={true}
            largeContainer={true}
          />
          <TabsAndFilterBar
            sortBy={sortBy}
            setSortBy={setSortBy}
            statusTabs={statusTabs}
            setStatusTabs={setStatusTabs}
            search={search}
            handleSearchChange={handleSearchChange}
            // exLargeContainer={true}
            largeContainer={true}
          />
          <div className="jobs-section">
            <div className="container large">
              <div className="row">
                <ProviderErrorLoadingPagination
                  reducer="job"
                  action="getMyJobsAsyncThunk"
                  asyncThunk={getMyJobsAsyncThunk}
                  dataKey="myJobs"
                  Component={
                    viewMode === "grid"
                      ? JobCardComponent
                      : JobCardComponentListView
                  }
                  emptyMessage="No jobs found!"
                  emptyComponent={<NoJobNewComponent />}
                  pagination
                  itemKey="job"
                  componentProps={{
                    jobTitle: "Job Title",
                    brandName: "Brand Name",
                    jobPostedTime: "",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
