import React, { useState, useEffect } from "react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SearchIcon from "../assets/images/icons/search-icon-wrapper.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import jobFileIcon from "../assets/images/icons/order-file-icon.svg";
import filterIcon from "../assets/images/icons/filter-icon.svg";
import statusIcon from "../assets/images/icons/status-icon.svg";
import diamondIcon from "../assets/images/icons/diamond-icon.svg";
import videoFileLight from "../assets/images/icons/video-file-light-icon.svg";
import calendaricon from "../assets/images/icons/calendar-simple-icon.svg";
import JobDetailFullSection from "../components/JobDetailFullSection";
import ViewProfileDetailModal from "../components/ViewProfileDetailModal";
import {
  getJobAsyncThunk,
  getJobDetailsAsyncThunk,
} from "../redux/pagesSlices/jobSlice";
import { dateFormat } from "../utils/format";
import JobApplicants from "../components/Job/JobApplicants";
import HiredCreators from "../components/Job/HiredCreators";
import SideBarComponent from "../components/SideBarComponent";
import { setValueIfExist } from "../utils/utils";
import { getJobApplicantsAsyncThunk } from "../redux/pagesSlices/applicationSlice";
import ViewJobDetailModal from "../components/modals/ViewJobDetailModal";
export default function JobDetailPage() {
  const d = useDispatch();
  const { id: jobId } = useParams();
  console.log("🚀 ~ JobDetailPage ~ paramsId:", jobId);
  const jobs = useSelector((state) => state.job?.jobDetails);
  // console.log("🚀 ~ JobDetailPage ~ jobs:", jobs);
  console.log("job id on job detail page", jobId);
  

  useEffect(() => {
    if (jobId) {
      d(getJobDetailsAsyncThunk(jobId));
    }
  }, [d, jobId]);

  const [jobDetailTabs, setJobDetailTabs] = useState("applicants");
  const [viewJobDetailModal, setViewJobDetailModal] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("createdAt:desc");
  const [selectedFilters, setSelectedFilters] = useState({
    promotionType: [],
    preferredGender: "All",
    AgeRange : []
  });
  const jobDetailPageTabsHandler = (tab) => {
    setJobDetailTabs(tab);
  };
  const sideBarOpenHandler = () => {
    setSideBarOpen(!sideBarOpen);
  };
  const applications = useSelector((s) => s.application.jobApplicants);
  useEffect(() => {
    const params = {
      populate: "creatorId",
      sortBy,
      ...(selectedFilters.promotionType?.length > 0 && {
        types: selectedFilters.promotionType,
      }),
      ...(selectedFilters.preferredGender !== "All" && {
        gender: selectedFilters.preferredGender.toLowerCase() || "All",
      }),
      ...(selectedFilters.AgeRange !== "All" && {
        ageRange: selectedFilters.AgeRange
      }),
        ...(search && { userName: search }),
        // ...(statusTabs && statusTabs !== "all" ? { status: statusTabs } : {}),
      
    };
    setTimeout(() => {
      d(
        getJobApplicantsAsyncThunk({
          id: jobId,
          params : {...params},
        })
      );
    }, 500);
  }, [search, d, sortBy,jobId,selectedFilters]);

  const viewJobDetailModalOpenHandler = () => {
    setViewJobDetailModal(true);
  };
  const viewJobDetailModalCloseHandler = () => {
    setViewJobDetailModal(false);
  };


  return (
    <div className="main-inner-page-wrapper">
      <SideBarComponent
        sideBarOpen={sideBarOpen}
        sideBarOpenHandler={sideBarOpenHandler}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        clearAllFilters={() =>
          setSelectedFilters({
            categories: [],
            promotionType: [],
            status: "All",
            preferredGender: "All",
            AgeRange : []
          })
        }
        arrangementArray={
          [
            { name: "PromotionType", type: "multiple" },
            { name: "PreferredGender", type: "single" },
            { name: "AgeRange", type: "single" },
          ]
        }
      />
      <div className={`inner-page-wrapper ${sideBarOpen ? "active" : ""}`}>
        <div className="job-detail-page">
          <div className="container">
            <div className="detail-page-main-title-wrapper">
              <div className="inner-wrapper">
                <div className="upper-section-wrapper">
                  <div className="row">
                    <div className="col-md-7 align-self-center">
                      <div className="content-wrapper">
                        <div className="back-btn-wrapper">
                          <Link to="/dashboard/my-jobs">
                            <MdOutlineKeyboardArrowLeft />
                            <strong className="medium-bold">
                              Back to jobs
                            </strong>
                          </Link>
                        </div>
                        <div className="main-title-wrapper">
                          <h3>{jobs?.job?.jobTitle} </h3>
                          <div className="main-wrapper">
                            <div className="title">
                              <p>
                                Posted:{" "}
                                <span>{dateFormat(jobs?.job?.createdAt)} </span>
                              </p>
                            </div>
                            <div className="title">
                              <p>
                                Deadline:{" "}
                                <span>{dateFormat(jobs?.job?.updatedAt)} </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 align-self-center">
                      <div className="btn-wrapper">
                        <a
                          href="#"
                          onClick={() => {
                            jobDetailPageTabsHandler("job_detail");
                            viewJobDetailModalOpenHandler();
                          }}
                          className="btn-style blue-border"
                        >
                          <img src={jobFileIcon} alt="jobFileIcon" /> View job
                          details
                        </a>
                        <div className="icon-wrapper">
                          <a
                            href="#"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: "100%", height: "100%" }}
                          >
                            <BsThreeDots />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="job-basic-short-info-main-wrapper mt-3">
                    <div className="text-wrapper">
                      <div className="title">
                        <img src={statusIcon} alt="statusIcon" />
                        <span>Status</span>
                      </div>
                      <span className="bg dark in_progress">
                        {jobs?.job?.status}
                      </span>
                    </div>
                    <div className="text-wrapper">
                      <div className="title">
                        <img src={diamondIcon} alt="statusIcon" />
                        <span>Average ROI</span>
                      </div>
                      <span className="bg dark">{jobs?.job?.ageRange}</span>
                    </div>
                    <div className="text-wrapper">
                      <div className="title">
                        <img src={diamondIcon} alt="statusIcon" />
                        <span>Average GMV</span>
                      </div>
                      <span className="bg dark">$89</span>
                    </div>
                    <div className="text-wrapper">
                      <div className="title">
                        <img src={videoFileLight} alt="statusIcon" />
                        <span>Average GMV/Video</span>
                      </div>
                      <span className="bg dark">$87</span>
                    </div>
                    <div className="text-wrapper">
                      <div className="title">
                        <img src={calendaricon} alt="statusIcon" />
                        <span>Number of creators</span>
                      </div>
                      <span className="bg dark">5</span>
                    </div>
                  </div>
                </div>
                <div className="nav-tabs-main-wrapper simple">
                  <ul className="tabs-wrapper">
                    <li
                      className={`${
                        jobDetailTabs === "applicants" ? "active" : ""
                      }`}
                    >
                      <a
                        href="#"
                        onClick={() => jobDetailPageTabsHandler("applicants")}
                      >
                        Applicants
                      </a>
                    </li>

                    <li
                      className={`${
                        jobDetailTabs === "hired_creators" ? "active" : ""
                      }`}
                    >
                      {jobs?.job?.status === "approved" ? (
                        ""
                      ) : (
                        <a
                          href="#"
                          onClick={() =>
                            jobDetailPageTabsHandler("hired_creators")
                          }
                        >
                          Nudge creators
                        </a>
                      )}
                    </li>
                    <li
                      className={`${
                        jobDetailTabs === "job_details" ? "active" : ""
                      }`}
                    >
                      <a
                        href="#"
                        onClick={() =>
                          jobDetailPageTabsHandler("job_details")
                        }
                      >
                        Job details
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {jobDetailTabs === "applicants" && <JobApplicants search={search} setSearch={setSearch} sortBy={sortBy} setSortBy={setSortBy} applications={applications} />}
            {jobDetailTabs === "job_details" && (
              <>
                <JobDetailFullSection jobs={jobs} />
              </>
            )}
          </div>
        </div>
      </div>
      <ViewJobDetailModal viewJobDetailModalCloseHandler={viewJobDetailModalCloseHandler} viewJobDetailModal={viewJobDetailModal} jobs={jobs} />
    </div>
  );
}
