import React, { useState } from "react";
import MemberShipCard from "./MemberShipCard";

const membershipPlans = [
  {
    planName: "Basic",
    price: 97,
    frequency: "per month",
    recommended: false,
    features: [
      "Hire, manage & analyze up to 3 creators.",
      "10 nudges per month",
    ],
  },
  {
    planName: "Pro",
    price: 197,
    frequency: "per month",
    recommended: true,
    features: [
      "Hire, manage & analyze up to 10 creators",
      "50 nudges per month",
    ],
  },
  {
    planName: "Enterprise",
    price: 497,
    frequency: "per month",
    recommended: false,
    features: [
      "Hire, manage & analyze unlimited creators",
      "250 nudges per month",
    ],
  },
];

export default function MemberShipOptionBrandSignupStep({
  setWithoutMembership,
  handleNext,
}) {
  const [loadingStates, setLoadingStates] = useState({}); // Track loading state for each plan

  const handleSetLoading = (planName, isLoading) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [planName]: isLoading,
    }));
  };

  return (
    <div className="member-ship-options-signup-step">
      <div className="job-post-inner-wrapper">
        <div className="modal-text-wrapper">
          <h2>Membership Options</h2>
          <p className="medium medium-bold">
            Choose your membership preferences for updates and opportunities
          </p>
        </div>
        <div className="member-ship-cards-main">
          <div className="row">
            {membershipPlans.map((plan, index) => (
              <div key={index} className="col-md-4">
                <MemberShipCard
                  planName={plan.planName}
                  price={plan.price}
                  frequency={plan.frequency}
                  features={plan.features}
                  recommended={plan.recommended}
                  loading={loadingStates[plan.planName]} // Pass the loading state for each plan
                  handleSetLoading={handleSetLoading} // Pass the function to set loading state
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
