import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { LuEyeOff, LuEye } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { userUpdatePasswordAsyncThunk } from "../../redux/pagesSlices/authSlice";
import { Spinner } from "react-bootstrap";

const passwordValidationSchema = Yup.object().shape({
  currentPass: Yup.string().required("Current password is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    ),
  passwordConfirm: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});
export default function DeleteAccountModal({
  deleteAccount,
  handleCloseDeleteAccount,
}) {
  const { loadings } = useSelector((s) => s.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const d = useDispatch();
  console.log("loadings", loadings);



  return (
    <div className="delete-account-modal-wrapper">
      <Modal
        show={deleteAccount}
        onHide={handleCloseDeleteAccount}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal change-password-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-inner-wrapper">
            <p className="medium dark_text mb-3">
              This action cannot be undone. This action is instant and will permanently delete your entire account. All your data and information will be deleted and cannot be recovered. Please type in your email to confirm.
            </p>
            <form action="#" className="form">
              <div className="field-wrapper">
                <div className="form-group">
                  <label htmlFor="reason">Tell us why you want to delete your account</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={handleCloseDeleteAccount}
            className="custom-btn blue mx-3"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loadings?.userUpdatePasswordAsyncThunk}
            className="custom-btn red-bg-btn "
          >
            {loadings?.userUpdatePasswordAsyncThunk ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Delete account"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
