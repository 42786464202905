import React from "react";
import { isImageURL, parseImagePathToURL } from "../../utils/globalFunctions";
import SentImageComponent from "./SentImageComponent";
import FileSentComponent from "./FileSentComponent";

export default function ReceivedMessage({ message }) {
  const messageDate = new Date(message?.createdAt);
  const hours = messageDate.getHours();
  const minutes = messageDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
  function decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  return (
    <div className="message-wrapper received-msg">
      <div className="message">
      {message?.content === "voice message" ? (
          <p
            className="p-2 rounded-3 dropdown_box"
            style={{ backgroundColor: "#f9fafb" }}
          >
            <audio controls>
              <source
                src={parseImagePathToURL(message.file)}
                type="audio/wav"
              />
              Your browser does not support the audio element.
            </audio>{" "}
          </p>
        ) : (
          <p
            dangerouslySetInnerHTML={{ __html: decodeHTML(message?.content) }}
          ></p>
        )}
        <span className="tile">{formattedTime}</span>
      </div>
      {message?.file && (
        <>
          <div className="uploaded-items-list">
            {isImageURL(message?.file) && (
              <SentImageComponent
              formateTime={formattedTime}
                imageSrc={parseImagePathToURL(message?.file)}
              />
            )}
            {!isImageURL(message?.file) && (
              <FileSentComponent
                file={message?.file}
                content={message?.content}
                time={formattedTime}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
