import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import profileImg from "../assets/images/icons/avatar-icon.svg";
import proposalImg from "../assets/images/proposal-img.svg";
import countryIcon from "../assets/images/icons/map-icon.svg";
import dollarSign from "../assets/images/icons/dollar-sign.svg";
import regroupIcon from "../assets/images/icons/regroup-icon.svg";
import profileBagIcon from "../assets/images/icons/shopping-bag-icon.svg";
import message from "../assets/images/icons/sms-icon.svg";
import { CiHeart } from "react-icons/ci";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getUserAsyncThunk } from "../redux/pagesSlices/userSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { getId, returnArray } from "../utils/utils";
import { Link } from "react-router-dom";
import ContractSettingModal from "./modals/ContractSettingModal";
import { getCreatorAsyncThunk } from "../redux/pagesSlices/creatorSlice";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { toggleApplicationFavoriteAsyncThunk } from "../redux/pagesSlices/applicationSlice";

function ViewProfileDetailModal({
  viewProfileModalCloseHandler = () => {},
  viewProfileModal,
  application,
  creatorId,
}) {
  const dispatch = useDispatch();
  console.log("User in modal", application);
  const [favorite, setFavorite] = useState(null);

  const creator = application?.creatorId || application?.application?.creatorId;
  const applicationDetails = application.application;
  console.log("applicationDetails in  view profile modal", applicationDetails);

  const [contractSettingModal, setContractSettingModal] = useState(false);
  const contractSettingModalOpenHandler = () => {
    setContractSettingModal(true);
  };
  const contractSettingModalCloseHandler = () => {
    setContractSettingModal(false);
  };

  useEffect(() => {
    if (creatorId) {
      dispatch(getCreatorAsyncThunk(creatorId));
    }
  }, [creatorId, dispatch]);

  useEffect(() => {
    if (favorite === null) setFavorite(application?.application?.favorite);
  }, [favorite, application]);
  const appliedCreator = useSelector((s) => s.creator?.creator);
  // console.log("APplied creator", appliedCreator);

  // console.log("User id in modal", userId);
  // useEffect(() => {
  //   if (userId) {
  //     dispatch(getUserAsyncThunk(userId));
  //   }
  // }, [userId]);
  const appliedUser = useSelector((s) => s.user.user);
  // console.log("users list", appliedUser);

  const userLocation = application?.location;

  return (
    <div className="container">
      <Modal
        show={viewProfileModal}
        onHide={viewProfileModalCloseHandler}
        className="view-profile-short-info-modal"
        centered
      >
        <Modal.Header>
          <Button
            variant="close"
            onClick={viewProfileModalCloseHandler}
            aria-label="Close"
            style={{ marginLeft: 0 }}
            className="cancel-btn "
          ></Button>

          <Link
            className="m-0 blue"
            to={`/dashboard/creators/${getId(
              application
            )}?applicationId=${getId(application?.application)}`}
          >
            View full Profile
          </Link>
        </Modal.Header>

        <Modal.Body>
          <div className="top-profile-box">
            <div className="profile-info">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="profile-left">
                    {appliedCreator?.avatar ? (
                      <img
                        src={parseImagePathToURL(appliedCreator?.avatar)}
                        alt=""
                        className="profile-img"
                      />
                    ) : (
                      <img src={profileImg} alt="" className="profile-img" />
                    )}
                    <div className="profile-text">
                      <h5>
                        {appliedCreator?.name} {appliedCreator?.lastName}
                      </h5>
                      <p>@{appliedCreator?.userName}</p>
                      <div className="profile-icons">
                        <div className="profile-text-icon">
                          <img src={countryIcon} alt="" />
                          <p>{userLocation}</p>
                        </div>
                        <div className="profile-text-icon">
                          <img src={profileBagIcon} alt="" />
                          <p>48 jobs</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="profile-btn">
                    {applicationDetails?.isHired ? (
                      <>
                        <button>
                          <Link to={`/dashboard/messages?id=${application?.application?.creatorId}&jobId=${application?.application?.jobId?._id}`} style={{color:"white"}}>
                          <img
                            src={message}
                            alt=""
                            style={{ color: "white" }}
                          />
                          Message Creator
                          </Link>
                        </button>
                        <div
                          className="heart-icon"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            dispatch(
                              toggleApplicationFavoriteAsyncThunk({
                                id: application?.application?._id,
                                data: { favorite: !favorite },
                                
                                // callBack: () => {
                                //     d(getJobApplicationsAsyncThunk({
                                //         id: item?.jobId,
                                //         params
                                //     }))
                                // }
                              })
                            );
                            setFavorite(!favorite);
                          }}
                        >
                          {favorite ? (
                            <FaHeart color={"#578BFC"} />
                          ) : (
                            <FaRegHeart color={"#578BFC"} />
                          )}
                        </div>
                        <div className="three-dot-icon">
                          <BsThreeDots fontSize={20} />
                        </div>
                      </>
                    ) : (
                      <button onClick={contractSettingModalOpenHandler}>
                        Hire creator
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="top-profile-bottom-text">
              <div className="profile-bottom-text">
                <p>Estimated ROI</p>
                <span>3.5X</span>
              </div>
              <div className="profile-bottom-text">
                <p>GMV (last 28 days)</p>
                <span>$500</span>
              </div>
              <div className="profile-bottom-text">
                <p>GMV per video</p>
                <span>$900</span>
              </div>
            </div>
          </div>
          <div className="profile-center-box">
            <div className="main-center-profile">
              <div className="center-text">
                <h1>Proposal details</h1>
                <h6>What makes you unique for this job?</h6>
                <p>{appliedCreator?.bio}</p>
              </div>
            </div>

            {applicationDetails?.isHired && (
              <div className="image-container">
                <strong className="dark semi_bold">Recent Works</strong>
                <div className="row mt-3">
                  <div className="col-md-4">
                    <div className="image-side-text-parent">
                      <img src={proposalImg} alt="png img" />
                      <div className="text-icon-parent">
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={regroupIcon} alt="Regrouplight icon" />
                            <span className="roi-text">ROI</span>
                          </div>
                          <span className="text-3x">3x</span>
                        </div>
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={dollarSign} alt="dollarSign" />
                            <span className="roi-text">GMV</span>
                          </div>
                          <span className="text-3x">$89</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="image-side-text-parent">
                      <img src={proposalImg} alt="png img" />
                      <div className="text-icon-parent">
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={regroupIcon} alt="Regrouplight icon" />
                            <span className="roi-text">ROI</span>
                          </div>
                          <span className="text-3x">3x</span>
                        </div>
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={dollarSign} alt="dollarSign" />
                            <span className="roi-text">GMV</span>
                          </div>
                          <span className="text-3x">$89</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="image-side-text-parent">
                      <img src={proposalImg} alt="png img" />
                      <div className="text-icon-parent">
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={regroupIcon} alt="Regrouplight icon" />
                            <span className="roi-text">ROI</span>
                          </div>
                          <span className="text-3x">3x</span>
                        </div>
                        <div className="text-icon">
                          <div className="inner-text-icon">
                            <img src={dollarSign} alt="dollarSign" />
                            <span className="roi-text">GMV</span>
                          </div>
                          <span className="text-3x">$89</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bordered-box-text">
                  <div className="video-prices">
                    <h4>Live prices</h4>
                    <div class="price-option">
                      <div className="price-card-sec">
                        {returnArray(
                          application?.livePrices ??
                            application?.offeredLivePrice
                        ).length > 0 ? (
                          returnArray(
                            application?.livePrices ??
                              application?.offeredLivePrice
                          ).map((price, index) => (
                            <div class="price-card">
                              <span className="alpha-text">
                                {price?.count} Live/m
                              </span>
                              <span className="dollar-text">
                                ${price?.price}
                              </span>
                            </div>
                          ))
                        ) : (
                          <div class="price-card">
                            <span className="alpha-text">
                              Not available for this creator
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="price-card-sec"></div>
                    </div>
                  </div>
                  <div className="video-prices">
                    <h4>Video prices</h4>
                    <div class="price-option">
                      <div className="price-card-sec">
                        {returnArray(
                          application?.videoPrices ??
                            application?.offeredVideoPrice
                        ).length > 0 ? (
                          returnArray(
                            application?.videoPrices ??
                              application?.offeredVideoPrice
                          ).map((price, index) => (
                            <div class="price-card">
                              <span className="alpha-text">
                                {price.count} Videos/m
                              </span>
                              <span className="dollar-text">
                                ${price.price}
                              </span>
                            </div>
                          ))
                        ) : (
                          <div class="price-card">
                            <span className="alpha-text">
                              Not available for this creator
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!applicationDetails?.isHired && (
              <>
                <div className="profile-price-cards">
                  <strong className="dark semi_bold">Price Videos</strong>
                  <div className="row">
                    {returnArray(
                      application?.videoPrices ?? application?.offeredVideoPrice
                    ).length > 0 ? (
                      returnArray(
                        application?.videoPrices ??
                          application?.offeredVideoPrice
                      ).map((price, index) => (
                        <div className="col-lg-3 col-md-6">
                          <div className="price-cards">
                            <p>{price?.count} Videos per month</p>
                            <h1>${price?.price}</h1>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-lg-3 col-md-6">
                        <div className="price-cards">
                          <p>Not available for this creator</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="profile-price-cards live-cards">
                  <h6>Price Lives</h6>
                  <div className="row">
                    {returnArray(
                      application?.livePrices ?? application?.offeredLivePrice
                    ).length > 0 ? (
                      returnArray(
                        application?.livePrices ?? application?.offeredLivePrice
                      ).map((price, index) => (
                        <div className="col-lg-3 col-md-6">
                          <div className="price-cards">
                            <p>{price?.count} Live per month</p>
                            <h1>${price?.price}</h1>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-lg-3 col-md-6">
                        <div className="price-cards">
                          <p>Not available for this creator</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {applicationDetails?.isHired && (
            <div crlassName="bottom-box">
              <h6>Brand note</h6>
              <p>
                Hey, is this your best prices for videos? we have a deal if you
                can come down to $200
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {contractSettingModal && (
        <ContractSettingModal
          creator={creator}
          viewProfileModalCloseHandler={viewProfileModalCloseHandler}
          applicationDetails={applicationDetails}
          contractSettingModal={contractSettingModal}
          contractSettingModalCloseHandler={contractSettingModalCloseHandler}
        />
      )}
    </div>
  );
}

export default ViewProfileDetailModal;
