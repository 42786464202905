import React from "react";
import { FieldArray, ErrorMessage } from "formik";
import { IoMdAdd } from "react-icons/io";

export default function VideoSamplesJobPostStep({ values, setFieldValue }) {
  console.log("values", values);
  return (
    <div className="inner-step-wrapper">
      <div className="title-wrapper">
        <h3 className="step-title">Video Samples</h3>
      </div>
      <p className="dark_text medium-bold mb-2">
        Share relevant video links that can help the creator understand exactly
        what you want.
      </p>

      <FieldArray name="videoUrl">
        {({ push, remove }) => {
          const videoLinksArray = values.videoUrl
            ? values.videoUrl.split(",").map((url) => url.trim()) // Convert string to array
            : [""];

          return (
            <div className="form">
              {videoLinksArray.map((url, index) => (
                <div key={index} className="field-wrapper">
                  <div className="form-group">
                    <label htmlFor={`videoUrl.${index}`}>
                      Video link {index + 1}
                    </label>
                    <input
                      type="text"
                      placeholder="Paste link"
                      className="form-control blue-focus"
                      value={videoLinksArray[index]} // Bind to array
                      onChange={(e) => {
                        const newLinksArray = [...videoLinksArray];
                        newLinksArray[index] = e.target.value; // Update link at index
                        setFieldValue(
                          "videoUrl",
                          newLinksArray.filter((link) => link).join(", ")
                        );
                      }}
                    />
                  </div>
                </div>
              ))}
              <ErrorMessage
                name={`videoUrl`}
                component="span"
                className="error-msg"
              />
              <div className="add-more-link-btn-wrapper">
                <button
                  type="button"
                  className="custom-btn blue mt-2  medium-bold"
                  onClick={() => {
                    push(""); // Add a new link input
                    const newLinksArray = [...videoLinksArray, ""];
                    setFieldValue("videoUrl", newLinksArray.join(", ")); // Convert array back to string
                  }}
                >
                  <IoMdAdd /> Add another link
                </button>
              </div>
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
}
