import React from "react";

import noJobImgNew from '../assets/images/no-job-img-new.svg'
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";

export default function NoJobNewComponent () {
    return(
        <div className="no-jobs-main-wrapper">
        <div className="main-wrapper">
            <div className="img-wrapper">
                <img src={noJobImgNew} alt="noJobImgNew" className="no-jobs-img" />
            </div>
            <div className="content-wrapper">   
                {/* <p className="medium medium-bold">You have not posted any jobs yet</p> */}
                <div className="btn-wrapper">
                    <Link to="/job-post" className="btn-style " style={{maxWidth : 'auto'}}><IoMdAdd/> Post a Job</Link>
                </div>
            </div>
        </div>
    </div>
    )
}