import React, { useState } from "react";

import { motion, AnimatePresence } from "framer-motion";
import ProgressBar from "react-bootstrap/ProgressBar";

import Modal from "react-bootstrap/Modal";

import logo from "../assets/images/icons/logo.svg";
import { FiArrowRight } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import IntroduceBrandSignupStep from "./IntroduceBrandSignupStep";
import IntroduceBrandSignupStep2 from "./IntroduceBrandSignupStep2";
import ContactDetailSignupStep from "./ContactDetailSignupStep";
import BrandFocusSignUpStep from "./BrandFocusSignUpStep";
import PaymentDetailBrandSignupStep from "./PaymentDetailBrandSignupStep";
import MemberShipOptionBrandSignupStep from "./MemberShipOptionBrandSignupStep";
import BrandSignUpWelcomeToBMC from "./BrandSignUpWelcomeToBMC";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { updateBrandProfileAsyncThunk } from "../redux/pagesSlices/brandSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

const stepZeroSchema = Yup.object({
  name: Yup.string().required("Brand name is required"),
  position: Yup.string(),
});

const stepOneSchema = Yup.object({
  description: Yup.string().required("Description is required"),
  avatar: Yup.mixed().nullable(),
});

const stepTwoSchema = Yup.object({
  phone: Yup.string().matches(
    /^(\+?[1-9]\d{0,2})?\d{10,15}$/,
    "Enter a valid phone number (10-15 digits, with optional + for international format)"
  ),
  website: Yup.string().nullable().url("Enter a valid URL"), // Ensures it is a proper URL if provided
});

const stepThreeSchema = Yup.object({
  categories: Yup.array()
    .of(Yup.string())
    .min(1, "Please select at least one category")
    .required("Category selection is required"),
});

export default function BrandSignupStepperModal(props, { modalCloseHandler }) {
  const { brand } = useSelector((s) => s.auth);
  const { loadings } = useSelector((s) => s.brand);
  const d = useDispatch();
  const totalSteps = 6;
  const [activeStep, setActiveStep] = useState(brand?.steps + 1 || 0);
  const [withoutMembership, setWithoutMembership] = useState(false);
  const progress = ((activeStep + 1) / totalSteps) * 100;

  const handleNext = () => {
    if (activeStep < totalSteps - 1) setActiveStep(activeStep + 1); // Adjust based on the number of steps
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const handleSubmit = async (values, { resetForm, errors }) => {
    const brandData = {
      name: values.name,
      position: values.position,
      description: values.description,
      avatar: values.avatar,
      phone: values.phone,
      website: values.website,
      categories: values.categories,
      steps: 3,
    };

    d(
      updateBrandProfileAsyncThunk({
        data: brandData,

        callback: () => {
          handleNext();
        },
      })
    );
  };

  return (
    <div className="job-post-modal-component">
      <Modal
        {...props}
        size="lg"
        className="job-post-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={{
            name: brand?.name || "",
            position: brand?.position || "",
            description: brand?.description || "",
            avatar: null,
            phone: brand?.phone || "",
            website: brand?.website || "",
            categories: brand?.categories || [],
          }}
          validationSchema={
            activeStep === 0
              ? stepZeroSchema
              : activeStep === 1
              ? stepOneSchema
              : activeStep === 2
              ? stepTwoSchema
              : activeStep === 3
              ? stepThreeSchema
              : stepZeroSchema
          }
          onSubmit={activeStep === 3 ? handleSubmit : handleNext}
        >
          {({ isSubmitting, values, errors, setFieldValue }) => {
            return (
              <Form className="form">
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    {activeStep !== 7 && (
                      <div className="logo-wrapper">
                        <img src={logo} alt="logo" />
                      </div>
                    )}
                    {activeStep === 7 && (
                      <button
                        className="custom-btn blue medium-bold back-btn"
                        onClick={() => setActiveStep(5)}
                      >
                        <FiArrowLeft /> Back
                      </button>
                    )}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="job-post-steps-wrapper">
                    <motion.div layout className="step-content">
                      <AnimatePresence mode="wait">
                        <motion.div
                          key={activeStep}
                          initial={{ opacity: 0, x: 100 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: -100 }}
                          transition={{ duration: 0.5 }}
                        >
                          {activeStep === 0 && (
                            <>
                              <IntroduceBrandSignupStep />
                            </>
                          )}
                          {activeStep === 1 && (
                            <>
                              <IntroduceBrandSignupStep2
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            </>
                          )}
                          {activeStep === 2 && (
                            <>
                              <ContactDetailSignupStep
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            </>
                          )}
                          {activeStep === 3 && (
                            <>
                              <BrandFocusSignUpStep values={values} />
                            </>
                          )}
                          {activeStep === 4 && (
                            <>
                              {/* <PaymentDetailBrandSignupStep /> */}
                              <MemberShipOptionBrandSignupStep
                                setWithoutMembership={setWithoutMembership}
                                handleNext={handleNext}
                              />
                            </>
                          )}
                          {activeStep === 5 && (
                            <>
                              <BrandSignUpWelcomeToBMC
                                withoutMembership={withoutMembership}
                                values={values}
                              />
                            </>
                          )}
                          {activeStep === 6 && <></>}
                          {activeStep === 7 && (
                            <>{/* <JobPostShopRatesSamplePreview />  */}</>
                          )}
                        </motion.div>
                      </AnimatePresence>
                    </motion.div>
                  </div>
                </Modal.Body>
                {activeStep !== 5 && activeStep !== 6 && (
                  <>
                    <Modal.Footer>
                      <ProgressBar now={progress} />
                      <button
                        type="button"
                        className="btn-style"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        <FiArrowLeft /> Back
                      </button>
                      <button
                        className="btn-style"
                        type="submit"
                        disabled={activeStep === totalSteps || activeStep === 4}
                      >
                        {loadings?.updateBrandProfileAsyncThunk ? (
                          <Spinner size="sm" />
                        ) : (
                          <>
                            Next <FiArrowRight />{" "}
                          </>
                        )}
                      </button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
