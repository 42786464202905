import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import brandContent from "../assets/images/brandImage.png";
import LocationIcon from "../assets/images/icons/LocationIcon.svg";
import PhoneIcon from "../assets/images/icons/PhoneIcon.svg";
import globeIcon from "../assets/images/icons/globeIcon.svg";
import tiktokIcon from "../assets/images/icons/tiktokIcon.svg";
import instaIcon from "../assets/images/icons/instaIcon.svg";
import facebookIcon from "../assets/images/icons/facebookIcon.svg";
import youtubeIcon from "../assets/images/icons/youtubeIcon.svg";
import Editfill from "../assets/images/icons/Editfill.svg";
import EditProfileInfoModal from "../components/modals/EditProfileInfoModal";
import { useDispatch, useSelector } from "react-redux";
import { parseImagePathToURL } from "../utils/globalFunctions";
import { getId, returnArray } from "../utils/utils";
import { Link } from "react-router-dom";
import { updateBrandAsyncThunk, updateBrandImageAsyncThunk } from "../redux/pagesSlices/brandSlice";

const BrandContent = () => {
  const [editProfileModal, setEditProfileModal] = useState(false);

  const editProfileModalOpenHandler = () => setEditProfileModal(true);
  const editProfileModalCloseHandler = () => setEditProfileModal(false);

  const brand = useSelector((s) => s.auth.profile);
  const brandSocials = returnArray(brand?.socials);
  console.log("Brand socials", brandSocials);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  const handleUpdateCover = async() => {
    console.log("Received file", file);

    const formData = new FormData();
     formData.append("image", file);
    
    
    console.log("Form data", formData);
    await dispatch(
      updateBrandImageAsyncThunk({
        id : getId(brand),
        data: formData,
      })
    );
  };

  // console.log("Brand", brand);

  return (
    <div className="parent-container">
      <div className="container">
        <div className="single-field main-page-wrapper">
          <div className="row">
            <div className="col-md-7 align-self-center">
              <div className="main-image-conatiner">  
                {brand?.avatar ? (
                  <img
                    src={parseImagePathToURL(brand?.avatar)}
                    alt="Brand Image"
                  />
                ) : (
                  <img src={brandContent} alt="Brand Image" />
                )}{" "}
                
              </div>
              <div className="text-container">
                <div className="title-text">
                  <span>{brand?.name}</span>
                </div>
                <div className="info-text">
                  <div className="comp1">
                    <img
                      src={LocationIcon}
                      alt="Location"
                      className="compIcon"
                    />
                    <span>Texas, United States</span>
                  </div>
                  <div className="comp2">
                    <img src={PhoneIcon} alt="Phone" className="compIcon" />
                    <span>
                      <a href="tel : +1 00 000 0000">+1 00 000 0000</a>
                    </span>
                  </div>
                  <div className="comp3">
                    <img src={globeIcon} alt="globe" className="compIcon" />
                    <span>
                      <a href={brand?.website}>{brand?.website}</a>
                    </span>
                  </div>
                </div>
                {/* <input
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
                <button onClick={handleUpdateCover}>Update Image</button> */}
                <ul className="social-icons">
                  {brandSocials?.map((social, index) => {
                    // Choose the appropriate icon based on the type
                    let socialIcon;

                    switch (social.type) {
                      case "Facebook":
                        socialIcon = facebookIcon;
                        break;
                      case "Instagram":
                        socialIcon = instaIcon;
                        break;
                      case "TikTok":
                        socialIcon = tiktokIcon;
                        break;
                      case "YouTube":
                        socialIcon = youtubeIcon;
                        break;
                      default:
                        socialIcon = null; // Skip if no valid type
                    }

                    // Render only if the icon and URL are valid
                    return (
                      socialIcon &&
                      social.url && (
                        <li key={index}>
                          <Link
                            to={social.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={socialIcon} alt={`${social.type} Icon`} />
                          </Link>
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-5 align-self-center">
              <div className="edit-profile-button-parent text-end justify-content-end">
                <button onClick={editProfileModalOpenHandler}>
                  <img src={Editfill} alt="Editfill" />
                  <span>Edit profile</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="single-field">
          <div className="row">
            <div className="col-md-12">
              <div className="about-context">
                <span>About</span>
                <p>{brand?.description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="single-field">
          <div className="row">
            <div className="col-md-8">
              <div className="categories-section">
                <span>Categories</span>
                <div className="category-btns">
                  <ul>
                    {brand?.categories?.map((category, index) => (
                      <li className="li-categories" key={index}>
                        {category}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditProfileInfoModal
        editProfileModalCloseHandler={editProfileModalCloseHandler}
        editProfileModal={editProfileModal}
        brand={brand}
      />
    </div>
  );
};

export default BrandContent;
