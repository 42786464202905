import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { updateProfile } from "../../redux/pagesSlices/authSlice";

const notificationValidationSchema = Yup.object().shape({
  settings: Yup.object()
    .shape({
      notifications: Yup.boolean(),
      commentsNotifications: Yup.boolean(),
      newNotifications: Yup.boolean(),
      jobNotifications: Yup.boolean(),
      systemNotifications: Yup.boolean(),
    })
    .required("Notification settings are required"),
});

export default function NotificationTab() {
  const { user } = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  console.log("user", user);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(
      updateProfile({
        data: values,
      })
    ).then(() => {
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={{
        settings: { ...user?.settings },
      }}
      validationSchema={notificationValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, dirty, isSubmitting }) => (
        <Form>
          <div className="notification-tab-wrapper">
            <div className="portal-settings-tab-header">
              <strong className="dark large">Notification settings</strong>
            </div>
            <div className="portal-header-bottom-bar"></div>
            <div className="notification-bottom-content">
              <div className="setting">
                <div>
                  <h3>Push Notification</h3>
                  <p>
                    Allow push notification to get updates on your account
                    activities and more.
                  </p>
                </div>
                <label className="switch">
                  <Field
                    type="checkbox"
                    name="settings.notifications"
                    checked={values.settings.notifications}
                  />
                  <span className="slider"></span>
                </label>
              </div>
              <div className="notification-options">
                <p>Show notifications for:</p>
                <label>
                  <Field
                    type="checkbox"
                    name="settings.commentsNotifications"
                  />
                  <span>Comments and replies.</span>
                </label>
                <label>
                  <Field type="checkbox" name="settings.newNotifications" />
                  <span>New messages.</span>
                </label>
                <label>
                  <Field type="checkbox" name="settings.jobNotifications" />
                  <span>Job updates.</span>
                </label>
                <label>
                  <Field type="checkbox" name="settings.systemNotifications" />
                  <span>System updates.</span>
                </label>
              </div>
            </div>
            <div className="save-button-parent">
              <button
                type="submit"
                className="btn-style"
                disabled={isSubmitting || !dirty}
              >
                {isSubmitting ? <Spinner size="sm" /> : "Save"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
