import ReceivedMessage from "../Chat/ReceivedMessage";
import SentMessage from "../Chat/SentMessage";
import attatchIcon from "../../assets/images/icons/attatch-pin-icon.svg";
// import MicIcon from "../../assets/images/icons/mic-icon.svg";
import planeIcon from "../../assets/images/icons/plane-icon.svg";
import { useEffect, useRef, useState } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import {
  deleteMessageAsyncThunk,
  saveMessageAsyncThunk,
  sendFileAsyncThunk,
  setMessages,
  setRooms,
} from "../../redux/pagesSlices/chatSlice";
import {
  extractFileName,
  generateObjectId,
  isImageURL,
} from "../../utils/globalFunctions";
import { useChannel, usePresence, usePresenceListener } from "ably/react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import "react-loading-skeleton/dist/skeleton.css";
import { Spinner } from "react-bootstrap";
import { FaFile } from "react-icons/fa6";
import { Link } from "react-router-dom";
import AddAudioElement from "./voiceRecord";
import SendFileModal from "../modals/SendFileModal";
import SendPhotoModal from "../modals/SendFileModal";

const ChatSection = ({
  currentUser,
  user,
  isLoading,
  setPage,
  channelName,
  notification,
  page,
  totalPages,
  isInfiniteLoading,
}) => {
  const [showSelectedEmoji, setShowSelectedEmoji] = useState(false);
  const pickerRef = useRef(null);
  const [sendPhotoModal, setSendPhotoModal] = useState(false);
  const [sendFileModal, setSendFileModal] = useState(false);

  const handleEmojiSelect = (emoji) => {
    // setSelectedEmoji(emoji.native);
    setMessageText((prev) => prev + emoji.native);
    setShowSelectedEmoji(false);
  };
  const showEmojiPicker = () => {
    setShowSelectedEmoji(true);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowSelectedEmoji(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const bottomRef = useRef(null); // Assuming user_id is passed as a query param

  const dispatch = useDispatch();
  const rooms = useSelector((state) => state.chat.rooms);
  const messages = useSelector((state) => state.chat?.messages?.results);

  // const chatSectionRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isFileSending, setIsFileSending] = useState(false);

  const [messageText, setMessageText] = useState("");

  const { channel } = useChannel(channelName, async (message) => {
    // save to db
    if (message.name === "chat-message") {
      dispatch(setMessages([message?.data, ...messages]));
      dispatch(
        setRooms(
          rooms
            .map((room) => {
              if (room.name === channelName) {
                return {
                  ...room,
                  lastMessage: message?.data?.content,
                  lastMessageTimestamp: new Date(),
                };
              }
              return room;
            })
            .sort((a, b) => {
              return (
                new Date(b.lastMessageTimestamp).getTime() -
                new Date(a.lastMessageTimestamp).getTime()
              );
            })
        )
      );
    }
  });
  usePresence({ channelName: channelName }, { isOnline: true });
  const { presenceData } = usePresenceListener(
    { channelName: channelName },
    (update) => {
      console.log(update);
    }
  );

  const handleDeleteMessage = async (messageId) => {
    dispatch(
      deleteMessageAsyncThunk({
        messageId,
        callBack: () => {
          const lastMessage = messages[messages.length - 1];
          const secondLastMessage = messages[messages.length - 2];
          const isLastMessage =
            lastMessage?.id || lastMessage?._id === messageId;
          notification.publish({
            name: "delete_message",
            data: {
              messageId,
              secondLastMessage,
              isLastMessage,
            },
          });
          dispatch(
            setMessages(
              messages.filter((msg) => (msg?.id || msg?._id) !== messageId)
            )
          );
          if (isLastMessage) {
            dispatch(
              setRooms(
                rooms.map((room) => {
                  if (room.name === channelName) {
                    return {
                      ...room,
                      lastMessage: secondLastMessage?.content,
                      lastMessageTimestamp: secondLastMessage?.createdAt,
                    };
                  }
                  return room;
                })
              )
            );
          }
        },
      })
    );
  };

  const sendChatMessage = async (e) => {
    if (isFileSending) return;

    if (e?.preventDefault instanceof Function) {
      e.preventDefault();
    }

    try {
      const _file = e?.file || file;
      setIsFileSending(true);
      const formData = new FormData();
      formData.append("file", _file);
      const response = _file
        ? await dispatch(sendFileAsyncThunk({ data: formData }))
        : null;
      const fileURL = response?.payload?.key;
      const filename = fileURL
        ? extractFileName(fileURL) === "recording.webm"
          ? "voice message"
          : isImageURL(fileURL)
          ? "image"
          : "file." + fileURL?.split(".").pop()
        : "";
      if (fileURL || messageText) {
        const messageContent = messageText || filename;
        // if (messageText) {
        const message = {
          id: generateObjectId(),
          file: fileURL,
          // content: messageText || response?.data?.originalname,
          content: messageContent,
          applicationId:
            currentUser?.applicationId?.id ||
            currentUser?.applicationId?._id ||
            currentUser?.applicationId,
          sender_id: user?.id || user?._id,
          recipient_id:
            currentUser?.id || currentUser?._id || currentUser?.user,
          createdAt: new Date(),
        };
        channel.publish({
          name: "chat-message",
          data: message,
        });
        const userPresent = presenceData.find(
          (p) =>
            p.clientId ===
            (currentUser?.id || currentUser?._id || currentUser?.user)
        );
        const messageData = {
          _id: message?.id,
          sender_id: message?.sender_id,
          recipient_id: message?.recipient_id,
          content: message?.content,
          file: message?.file,
          applicationId: message?.applicationId,
          read_by: !!userPresent
            ? [message?.recipient_id, message?.sender_id]
            : [message?.sender_id],
        };
        console.log("🚀 ~ sendChatMessage ~ messageData:", messageData);
        if (message?.sender_id === (user?.id || user?._id)) {
          dispatch(
            saveMessageAsyncThunk({
              messageData,
              callBack: (data) => {
                if (!!!userPresent) {
                  notification.publish({
                    name: "notification",
                    data: data?.message,
                  });
                }
                if (data?.isNewRoom) {
                  notification.publish({
                    name: "new room",
                    data: data,
                  });
                }
              },
            })
          );
        }
        setMessageText("");
        setFile(null);
        // setIsFileSending(false);
      } else {
        console.log(fileURL, messageText);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsFileSending(false);
      setSendPhotoModal(false);
      setSendFileModal(false);
    }
  };
  const onInfiniteLoadCallback = () => {
    if (page >= totalPages) return;
    setPage(page + 1);
  };

  return (
    <>
      <div id="scrollableDiv" className="messages-body-wrapper">
        {isLoading && !isInfiniteLoading ? (
          <>
            <Skeleton count={5} />
          </>
        ) : currentUser || true ? (
          <InfiniteScroll
            dataLength={messages.length}
            next={onInfiniteLoadCallback}
            initialScrollY={0}
            scrollThreshold={0.9}
            inverse={true}
            hasMore={page < totalPages}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {messages
              .slice()
              .reverse()
              .map((message, index) => {
                const isUser = message?.sender_id === (user?.id || user?._id);
                const isSystem = message?.type === "system";
                return (
                  <div
                    key={index}
                    className={`mb-3 ${
                      isUser ? "d-flex justify-content-end" : ""
                    }`}
                  >
                    {isUser ? (
                      !isSystem ? (
                        <SentMessage
                          handleDeleteMessage={() =>
                            handleDeleteMessage(message?.id)
                          }
                          message={message}
                        />
                      ) : (
                        <></>
                      )
                    ) : (
                      <ReceivedMessage message={message} />
                    )}
                  </div>
                );
              })}
          </InfiniteScroll>
        ) : null}
        <div ref={bottomRef} />
      </div>
      <div className="message-send-main-wrapper">
        <form onSubmit={sendChatMessage}>
          <textarea
            name="message"
            id="message"
            placeholder="Type message"
            className="form-control"
            value={messageText}
            disabled={channelName === "System"}
            onChange={(e) => setMessageText(e.target.value)}
          ></textarea>
          <div className="bottom-bar">
            <ul className="icon-list">
              <li>
                <label htmlFor="fileInput" className="custom-file-input">
                  <input
                    type="file"
                    id="fileInput"
                    className="file"
                    style={{ display: "none" }}
                    disabled={channelName === "System"}
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      if (e.target.files[0]) {
                        if (isImageURL(e.target.files[0].name)) {
                          setSendPhotoModal(true);
                        } else {
                          setSendFileModal(true);
                        }
                      }
                    }}
                  />
                  <img src={attatchIcon} alt="attatchIcon" />
                </label>
              </li>
              <li onClick={showEmojiPicker}>
                <Link href="#">
                  <span style={{ fontSize: 20 }}>{"🙂"} </span>
                </Link>
                {showSelectedEmoji && (
                  <>
                    <div ref={pickerRef} className="emoji-select-package">
                      <Picker
                        data={data}
                        onEmojiSelect={handleEmojiSelect}
                        theme="light"
                        className="emoji-picker-package"
                      />
                    </div>
                  </>
                )}
              </li>{" "}
              <li>
                <Link href="#">
                  {channelName !== "System" && (
                    <AddAudioElement
                      setFile={setFile}
                      sendChatMessage={sendChatMessage}
                    />
                  )}
                  {/* <img src={MicIcon} alt="b" /> */}
                </Link>
              </li>
            </ul>
            <div className="message-sent-icon">
              {isFileSending ? (
                <>
                  <Spinner animation="border" size="md" className="me-2" />
                </>
              ) : (
                <button
                  disabled={channelName === "System"}
                  className="msg-send-btn trasparent-btn"
                  type="submit"
                >
                  <img
                    src={planeIcon}
                    alt="planeIcon"
                    className={`${channelName === "System" ? "" : "active"}`}
                  />
                </button>
              )}
            </div>
          </div>
        </form>
        <SendPhotoModal
          loading={isFileSending}
          handleSend={sendChatMessage}
          messageText={messageText}
          setMessageText={setMessageText}
          sendPhotoModalCloseHandler={() => {setSendPhotoModal(false); setFile(null)}}
          photoURL={file instanceof File ? URL.createObjectURL(file) : ""}
          sendPhotoModal={sendPhotoModal}
        />
        <SendFileModal
          loading={isFileSending}
          handleSend={sendChatMessage}
          messageText={messageText}
          setMessageText={setMessageText}
          sendFileModalCloseHandler={() => {setSendFileModal(false); setFile(null)}}
          file={file}
          sendFileModal={sendFileModal}
        />
      </div>
    </>
  );
};

export default ChatSection;
