import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import userLightIcon from "../assets/images/icons/user-light-icon.svg";
import starlight from "../assets/images/icons/Star_light.svg";
import creditcardlight from "../assets/images/icons/Credit-card-light.svg";
import notification from "../assets/images/icons/notification-icon.svg";
import Chieldchecklight from "../assets/images/icons/Chield_check_light.svg";
import logoutlighticon from "../assets/images/icons/logout-light-icon.svg";
import deleteicon from "../assets/images/icons/deleteicon.svg";
import TickedCircle from "../assets/images/icons/TickedCircle.svg";
import Downloadlight from "../assets/images/icons/Download_light.svg";
import Downloadgreylight from "../assets/images/icons/Download_grey-light.svg";
import DeleteAccountModal from "../components/modals/DeleteAccountModal";
import AddPaymentModal from "../components/modals/AddPaymentModal";
import AccountTab from "../components/profileSettings/AccountTab";
import NotificationTab from "../components/profileSettings/NotificationTab";
import SecurityTab from "../components/profileSettings/SecurityTab";
import PaymentTab from "../components/profileSettings/PaymentTab";

const tabs = [
  { id: "accounts", icon: userLightIcon, name: "Accounts" },
  { id: "subscription", icon: starlight, name: "Subscription" },
  { id: "pay-out-method", icon: creditcardlight, name: "Payment methods" },
  { id: "notifications", icon: notification, name: "Notification Settings" },
  { id: "security", icon: Chieldchecklight, name: "Passwords & Security" },
];

const bottomTabs = [
  { id: "logout", icon: logoutlighticon, name: "Log Out" },
  { id: "delete", icon: deleteicon, name: "Delete Account" },
];

const PortalSettings = () => {
  const [activeTab, setActiveTab] = useState("accounts");
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [editCompanyProfileModal, setEditCompanyProfileModal] = useState(false);

  const handleOpenDeleteAccount = () => {
    setDeleteAccount(true);
  };
  const handleCloseDeleteAccount = () => {
    setDeleteAccount(false);
  };

  return (
    <div className="protalsettings-main-container">
      <div className="container">
        <span className="title">Settings</span>
        <div className="row">
          <div className="col-md-3">
            <div className="settings-menu">
              <ul className="top-ul">
                {tabs.map((tab) => (
                  <li
                    key={tab.id}
                    className={`menu-item ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    <img src={tab.icon} alt={tab.name} />
                    <span>{tab.name}</span>
                  </li>
                ))}
              </ul>
              <div className="custom-bar"></div>
              <ul className="bottom-ul">
                {bottomTabs.map((tab) => (
                  <li
                    key={tab.id}
                    className={`menu-item ${
                      activeTab === tab.id ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    <img src={tab.icon} alt={tab.name} />
                    <span>{tab.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-9">
            <div className="form-section-parent">
              {activeTab === "accounts" && <AccountTab />}
              {activeTab === "subscription" && (
                <div className="subscription-tab-main-wrapper">
                  <div className="portal-settings-tab-header">
                    <strong className="dark large">Subscription</strong>
                  </div>
                  <div className="portal-header-bottom-bar"></div>
                  <div className="subscription-bottom-content">
                    <div className="title">
                      <span>Current Plan</span>
                    </div>
                    <div className="bordered-box">
                      <div className="bordered-box-sec-1">
                        <h6>PREMIUM</h6>
                        <span>$1799 </span>
                        <sub>per quarter</sub>
                        <div className="ordered-text">
                          <h6>Includes:</h6>
                          <div className="ordered-text-comp">
                            <img src={TickedCircle} alt="TickedCircle" />
                            <span>Post a Job</span>
                          </div>
                          <div className="ordered-text-comp">
                            <img src={TickedCircle} alt="TickedCircle" />
                            <span>Hire Unlimited Creators</span>
                          </div>
                          <div className="ordered-text-comp">
                            <img src={TickedCircle} alt="TickedCircle" />
                            <span>Manage Your Roster Of Creators</span>
                          </div>
                          <div className="ordered-text-comp">
                            <img src={TickedCircle} alt="TickedCircle" />
                            <span>Measure ROI</span>
                          </div>
                        </div>
                      </div>

                      <div className="bordered-box-sec-2">
                        <p className="text-section">
                          <span className="text1">Subscription end date</span>
                          <span className="text2">Nov 13, 2024</span>
                        </p>
                      </div>
                    </div>
                    <div className="subscription-tab-table">
                      <div className="table-headings">
                        <span>Billing history</span>
                        <div className="sec2-text">
                          <button className="trasparent-btn">
                            <span>Download all</span>
                            <img src={Downloadgreylight} alt="download" />
                          </button>
                        </div>
                      </div>
                      <table class="custom-table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Method</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>January 3,2024</td>
                            <td>$2000</td>
                            <td>Membership subscription</td>
                            <td>Card </td>
                            <td>
                              <button className="trasparent-btn">
                                <img
                                  src={Downloadlight}
                                  alt="icon"
                                  class="icon"
                                />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>January 3,2024</td>
                            <td>$2000</td>
                            <td>Membership subscription</td>
                            <td>ACH</td>
                            <td>
                              <button className="trasparent-btn">
                                <img
                                  src={Downloadlight}
                                  alt="icon"
                                  class="icon"
                                />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>January 3,2024</td>
                            <td>$2000</td>
                            <td>Membership subscription</td>
                            <td>ACH</td>
                            <td>
                              <button className="trasparent-btn">
                                <img
                                  src={Downloadlight}
                                  alt="icon"
                                  class="icon"
                                />
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>January 3,2024</td>
                            <td>$2000</td>
                            <td>Membership subscription</td>
                            <td>Card</td>
                            <td>
                              <button className="trasparent-btn">
                                <img
                                  src={Downloadlight}
                                  alt="icon"
                                  class="icon"
                                />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "pay-out-method" && <PaymentTab />}
              {activeTab === "notifications" && <NotificationTab />}
              {activeTab === "security" && <SecurityTab />}
              {activeTab === "logout" && (
                <div>
                  <h3>Log Out</h3>
                  <p>You can log out of your account here.</p>
                </div>
              )}
              {activeTab === "delete" && (
                <div>
                  <h3>Delete Account</h3>
                  <p>
                    Proceed with caution if you wish to delete your account.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteAccountModal
        deleteAccount={deleteAccount}
        handleCloseDeleteAccount={handleCloseDeleteAccount}
      />
    </div>
  );
};

export default PortalSettings;

{
  /* <div className="form-section-parent">
              <div className="header">
                <span>Account</span>
              </div>
              <div className="header-bottom-bar"></div>
              <div className="form-section">
          <form action="#" className="form">
          <div className="row first-section-input">
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="name">First Name</label>
      <input type="text" className="form-control" placeholder="John" />
    </div>
  </div>
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="name">Last Address</label>
      <input type="text" className="form-control" placeholder="Doe" />
    </div>
  </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <label htmlFor="name">Email</label>
      <input type="email" className="form-control" placeholder="email@example.com" />
    </div>
    </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <h3>Brand information </h3>
      <span>This information will appear on your future bills and invoices</span>
    </div>
    </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <label htmlFor="name">Email</label>
      <input type="email" className="form-control" placeholder="email@example.com" />
    </div>
    </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <label htmlFor="name">legal business name</label>
      <input type="email" className="form-control" placeholder="Burger King" />
    </div>
    </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <label htmlFor="name">Business phone number</label>
      <input type="tel" className="form-control" placeholder="+1 000 000 000 00" />
    </div>
    </div>
  </div>
  <div className="row">
  <div className="col-md-8">
    <div className="form-group">
      <label htmlFor="name">Business email address</label>
      <input type="email" className="form-control" placeholder="email@example.com" />
    </div>
    </div>
</div>
<div className="row first-section-input">
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="name">Tax type (VAT, EIN)</label>
      <Dropdown className="portalsettings-dropdown">
      <Dropdown.Toggle >
        <span>Select</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </div>
  </div>
  <div className="col-md-4">
    <div className="form-group">
      <label htmlFor="name">ID number</label>
      <input type="text" className="form-control" placeholder="John" />
    </div>
  </div>
  </div>

                </form>
          </div>
          <div className="save-button-parent">
                <button className="save-button">Save</button>
              </div>
          </div>  */
}
