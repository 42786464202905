import React, { useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import SearchIcon from "../assets/images/icons/search-icon-wrapper.svg";
import filterIcon from "../assets/images/icons/filter-icon.svg";
import listIcon from "../assets/images/icons/list-icon.svg";
import gridIcon from "../assets/images/icons/grid-icon.svg";
import { useSelector } from "react-redux";

const filterLabels = {
  "createdAt:desc": "Newest First",
  "createdAt:asc": "Oldest First",
  "jobTitle:asc": "A-Z",
  "jobTitle:desc": "Z-A",
};

export default function TabsAndFilterBar({
  jobsViewHandler,
  jobsView,
  statusTabs,
  setStatusTabs,
  handleSearchChange = () => {},
  search = "",
  sortBy,
  setSortBy,
  exLargeContainer,
  largeContainer,
}) {
  const { jobsCount } = useSelector((s) => s.job);

  const statusTabsHandler = (tab) => {
    setStatusTabs(tab);
  };

  return (
    <div className="tabs-and-filter-bar-main-wrapper">
      <div
        className={`container ${
          exLargeContainer ? "ex-large" : largeContainer ? "large" : ""
        }`}
      >
        <div className="row">
          <div className="col-md-7">
            <div className="nav-tabs-main-wrapper">
              <ul className="tabs-wrapper">
                <li className={`${statusTabs == "approved" ? "active" : ""}`}>
                  <a href="#" onClick={() => statusTabsHandler("approved")}>
                    Approved ({jobsCount?.approved || 0})
                  </a>
                </li>
                <li className={`${statusTabs == "pending" ? "active" : ""}`}>
                  <a href="#" onClick={() => statusTabsHandler("pending")}>
                    Pending ({jobsCount?.pending || 0})
                  </a>
                </li>
                <li className={`${statusTabs == "rejected" ? "active" : ""}`}>
                  <a href="#" onClick={() => statusTabsHandler("rejected")}>
                    Draft ({jobsCount?.rejected || 0})
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-5 align-self-center">
            <div className="right-menu-wrapper">
              <div className="search-bar-wrapper">
                <img
                  src={SearchIcon}
                  alt="SearchIcon"
                  className="search-icon"
                />
                <input
                  onChange={handleSearchChange}
                  value={search}
                  type="text"
                  className="form-control"
                  placeholder="Search Jobs"
                />
              </div>
              <div className="filter-and-view-wrapper">
                <div className="sort-by-wrapper">
                  <strong>Sort by: </strong>
                  <DropdownButton
                    onSelect={setSortBy}
                    id={`dropdown-button-drop-down`}
                    drop={"down"}
                    variant="secondary"
                    title={filterLabels[sortBy]}
                  >
                    <Dropdown.Item eventKey="createdAt:desc">
                      Newest First
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="createdAt:asc">
                      Oldest First
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="jobTitle:asc">A-Z</Dropdown.Item>
                    <Dropdown.Item eventKey="jobTitle:desc">Z-A</Dropdown.Item>
                  </DropdownButton>
                  {/* <img src={filterIcon} alt="filterIcon" className="filter-icon" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
