import React, { useEffect } from "react";
import { RxArrowTopRight } from "react-icons/rx";
import { Link } from "react-router-dom";
import jobImg1 from "../assets/images/job-img-1.svg";
import brandImg from "../assets/images/brand-img-1.svg";
import DashboardMyMessages from "../components/DashboardMyMessages";
import { FaCalendarAlt } from "react-icons/fa";
import { CircularProgressbar } from "react-circular-progressbar";
import videofile from "../assets/images/icons/video-file-wrapper.svg";
import liveIcon from "../assets/images/icons/live-icon.svg";
import shopBlurIcon from "../assets/images/icons/shop-blue-icon.svg";
import usersPinkIcon from "../assets/images/icons/users-pink-icon.svg";
import usersOrange from "../assets/images/icons/users-orange-icon.svg";
import treningGreen from "../assets/images/icons/tranding-up-green.svg";
import kickStartImg from "../assets/images/kick-start-img.png";
import { useDispatch, useSelector } from "react-redux";
import { getBrandsAnalyticsAsyncThunk } from "../redux/pagesSlices/brandSlice";
import { fetchRoomsAsyncThunk } from "../redux/pagesSlices/chatSlice";
import { IoMdTrendingUp } from "react-icons/io";
import arrowInBoxIcon from '../assets/images/icons/arrow-in-box.png'
import kickStartBgImg from '../assets/images/kick-start-bg-img.png'
import { TiArrowRight } from "react-icons/ti";
import DashboardHomeBannerSlider from "../components/DashboardHomeBannerSlider";
import { getMyJobsAsyncThunk, getOngoingJobsAsyncThunk } from "../redux/pagesSlices/jobSlice";
import { parseImagePathToURL } from "../utils/globalFunctions";
import ProviderErrorLoadingPagination from "../components/ProviderErrorLoadingPagination";
import OnGoingJobsComponent from "../components/Job/OnGoingJobsComponent";
import NoJobNewComponent from "../components/NoJobNewComponent";

export default function DashboaerdHome() {
  const { user } = useSelector((s) => s.auth);
  const { brandsAnalytics } = useSelector((s) => s.brand);
  const jobsView = useSelector((s) => s.job?.myJobs);
  console.log("🚀 ~ DashboaerdHome ~ jobsView:", jobsView)
  const dispatch = useDispatch();

  const getData = () => {
    dispatch(getBrandsAnalyticsAsyncThunk());
    dispatch(fetchRoomsAsyncThunk());
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    dispatch(
      getMyJobsAsyncThunk({
        populate: "brandId",
        status: "approved",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getOngoingJobsAsyncThunk({
      applicantsCount: 10,
    }))
  },[dispatch])



  return (
    <div className="dashboard-page-home">
      <div className="container ex-large">
        <div className="welcome-title-wrapper mb-4">
          <h2 className="medium dark">Welcome back, {user?.firstName}</h2>
        </div>
        <div className="dashboard-banner-section mb-5">
          <div className="row">
            <div className="col-md-7">
              <div className="kickstart-wrapper">
                <div className="content-wrapper">
                  <h3 className="white">Kickstart Your Campaigns</h3>
                  <p className="light-white">Create a detailed job listing and let talented creators come to you.</p>
                  <a href="#" className="btn-style text white-btn">Post a job now <TiArrowRight /></a>
                </div>
                <div className="img-wrapper">
                  <img src={kickStartImg} alt="kickStartImg" />
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="banner-content-slider-section">
                <DashboardHomeBannerSlider />
              </div>
            </div>
          </div>
        </div>
        <div className="inner-wrapper mb-5">
          <div className="main-title-wrapper mb-4">
            <strong className="dark large semi_bold">Account Summary </strong>
          </div>
          <div className="row">
            <div className="col-md-3 mb-3 mb-md-0">
              <div className="card-wrapper">
                <div className="main-wrapper">
                  <div className="content-wrapper">
                    <p className="medium medium-bold">All Jobs</p>
                    <h3 className="large">{brandsAnalytics?.jobs?.approved ?? 0}</h3>

                  </div>
                  <div className="img-wrapper light-blue">
                    <img src={shopBlurIcon} alt="shopBlurIcon" />
                  </div>
                </div>


                <div className="short-des">
                  <span className="small">  Approved: <span>{brandsAnalytics?.jobs?.approved}</span> </span>
                  <span className=""> Pending Approval: <span style={{ color: '#DBA32A' }}>{brandsAnalytics?.jobs?.pending}</span> </span>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <div className="card-wrapper">
                <div className="main-wrapper">
                  <div className="content-wrapper">
                    <p className="medium medium-bold">Hired Creators</p>
                    <h3 className="large">{brandsAnalytics?.hiredCount}</h3>

                  </div>
                  <div className="img-wrapper light-pink">
                    <img src={usersPinkIcon} alt="usersPinkIcon" />
                  </div>
                </div>
                <div className="short-des">
                  <span className="">Pending Creator Applications: <span>{brandsAnalytics?.applications ?? 0}</span></span>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <div className="card-wrapper">
                <div className="main-wrapper">
                  <div className="content-wrapper">
                    <p className="medium medium-bold">Applicants</p>
                    <h3 className="large">{brandsAnalytics?.applications}</h3>

                  </div>
                  <div className="img-wrapper light-orange">
                    <img src={usersOrange} alt="usersOrange" />
                  </div>
                </div>
                <div className="short-des">
                  <span className="">

                    <span><IoMdTrendingUp /> 8.5%</span> 3  More from yesterday
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <div className="card-wrapper">
                <div className="main-wrapper">
                  <div className="content-wrapper">
                    <p className="medium medium-bold">Total GMV Gen.</p>
                    <h3 className="large">
                      ${brandsAnalytics?.totalAmountSpent?.formatted}
                    </h3>

                  </div>
                  <div className="img-wrapper light-green">
                    <img src={treningGreen} alt="treningGreen" />
                  </div>
                </div>
                <div className="short-des">
                  <span className="">
                    <span><IoMdTrendingUp /> ${brandsAnalytics?.monthlyAmountSpent?.formatted}</span>  {' '}
                    Up from yesterday
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-stats-section">
        <div className="container ex-large">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="main-inner-wrapper">
                <div className="main-title-wrapper">
                  <strong className="dark large semi_bold">Ongoing Jobs </strong>
                </div>
                <div className="main-content-wrapper">
                  {/* pending jobs status listing here  */}
                  {/* {jobsView?.results?.map((job, index) => (
                    <div key={index} className="ongoing-job-status">
                      <div className="job-info">
                        <div className="img-wrapper">
                          <a href="#">
                            <img
                              src={parseImagePathToURL(job?.images?.[0]) || jobImg1}
                              alt="jobImg1"
                              className="job-img"
                            />
                          </a>
                        </div>
                        <div className="text-wrapper">
                          <strong className="dark ">
                            <a href="#">
                              {job?.jobTitle}
                            </a>
                          </strong>
                        </div>
                      </div>
                      <div className="texts">
                        <p className="medium-bold"><span className="blue">3.5X </span> ROI</p>
                      </div>
                      <div className="texts">
                        <p className="medium-bold"><span className="blue">$1,200  </span> GMV Gen.</p>
                      </div>
                      <div className="all-uers-images-main">
                        <ul className="users-imgs-list">
                          {job?.image?.map((img, index) => (
                            <li key={index}>
                              <a href="#">
                                <img
                                  src={parseImagePathToURL(img)} // Use the parseImagePathToURL function
                                  alt={`Job Image ${index + 1}`}
                                />
                              </a>
                            </li>
                          ))}

                        </ul>
                        <strong className=" semi_bold">
                          <a href="#">+20 Creators</a>
                        </strong>
                      </div>
                      <div className="states-main-wrapper">
                        <div className="stats">
                          <div className="text blue">
                            <CircularProgressbar value={20} strokeWidth={16} />
                            <span className="white"> 2/5</span>
                          </div>
                          <div className="bottom">
                            <img src={videofile} alt="videofile" />
                            <span className="ex_small">Videos Created</span>
                          </div>
                        </div>
                        <div className="stats">
                          <div className="text pink">
                            <CircularProgressbar value={20} strokeWidth={16} />
                            <span className="white"> 2/5</span>
                          </div>
                          <div className="bottom">
                            <img src={liveIcon} alt="liveIcon" />
                            <span className="ex_small">Livestreams</span>
                          </div>
                        </div>
                        <div className="stats">
                          <div className="text gray">
                            <span className="">7</span>
                          </div>
                          <div className="bottom">
                            <FaCalendarAlt />
                            <span className="ex_small">Days to deadline</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))} */}
                  <ProviderErrorLoadingPagination 
                    reducer="job"
                    action="getOnGoingJobsAsyncThunk"
                    asyncThunk={getOngoingJobsAsyncThunk}
                    dataKey="onGoingJobs"
                    Component={OnGoingJobsComponent}
                    emptyMessage="No Ongoing Jobs"
                    emptyComponent={<NoJobNewComponent />}
                    pagination={false}
                    itemKey="ongoingjob"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
