import React from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function JobPostStepperComponent({ isActive, isCompleted }) {
  const { createJobAsyncThunk } = useSelector((s) => s.job.loadings);

  return (
    <div className="job-post-stepper-component-wrapper">
      <div className="stepper-header">
        <div className="container large">
          <div className="row">
            <div className="col-md-3 align-self-center">
              <div className="back-btn-wrapper">
                <Link to="/dashboard">
                  <MdKeyboardArrowLeft /> Back to dashboard
                </Link>
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <div className="steps-main-wrapper">
                <div className="steps-wrapper">
                  <div
                    className={`step-wrapper ${isActive(0) ? "active" : ""} ${
                      isCompleted(0) ? "complete" : ""
                    }`}
                  >
                    <div className="num">
                      {isCompleted(0) ? <FaCheck /> : <span>1</span>}
                    </div>
                    <div className="text">
                      <span>Job info</span>
                    </div>
                  </div>
                  <div
                    className={`step-wrapper ${isActive(1) ? "active" : ""} ${
                      isCompleted(1) ? "complete" : ""
                    }`}
                  >
                    <div className="num">
                      {isCompleted(1) ? <FaCheck /> : <span>2</span>}
                    </div>
                    <div className="text">
                      <span>Details</span>
                    </div>
                  </div>
                  <div
                    className={`step-wrapper ${isActive(2) ? "active" : ""} ${
                      isCompleted(2) ? "complete" : ""
                    }`}
                  >
                    <div className="num">
                      {isCompleted(2) ? <FaCheck /> : <span>3</span>}
                    </div>
                    <div className="text">
                      <span>Media</span>
                    </div>
                  </div>
                  <div
                    className={`step-wrapper ${isActive(3) ? "active" : ""} ${
                      isCompleted(3) ? "complete" : ""
                    }`}
                  >
                    <div className="num">
                      {isCompleted(3) ? <FaCheck /> : <span>4</span>}
                    </div>
                    <div className="text">
                      <span>Settings</span>
                    </div>
                  </div>
                  <div
                    className={`step-wrapper ${isActive(4) ? "active" : ""} ${
                      isCompleted(4) ? "complete" : ""
                    }`}
                  >
                    <div className="num">
                      {isCompleted(4) ? <FaCheck /> : <span>5</span>}
                    </div>
                    <div className="text">
                      <span>Review</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 align-self-center">
              <div className="save-btn-swrapper">
                <button
                  disabled={createJobAsyncThunk}
                  type="submit"
                  className="btn-style blue-border large_font"
                >
                  {createJobAsyncThunk ? (
                    <Spinner size="sm" />
                  ) : (
                    "Save and exit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
